export const getProductVariations: any = (variations: any[]) => {
  return variations.map((item) => {
    const attributes = item.attributes.nodes.map((item: any) => {
      return {
        attribute: item.name,
        value: item.value
      };
    });

    return {
      attributes,
      id: item.id,
      salePrice: item.salePrice,
      databaseId: item.databaseId,
      stockStatus: item.stockStatus,
      regularPrice: item.regularPrice,
      stockQuantity: item.stockQuantity
    };
  });
};
