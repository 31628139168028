import gql from "graphql-tag";
import { ProductProps, ProductVariation } from "../types/productTypes";

export interface OrderItem {
  orderId: number;
  total: string;
  quantity: number;
  product: ProductProps;
  variation: ProductVariation;
}

export interface Order {
  order: {
    id: string;
    orderId: string;
    currency: string;
    date: string;
    status: string;
    subtotal: string;
    total: string;
    orderNumber: string;
    paymentMethod: string;
    paymentMethodTitle: string;
    shippingTotal: string;
    billing: {
      address1: string;
      firstName: string;
      lastName: string;
      country: string;
      postcode: string;
      phone: string;
      email: string;
      state: string;
    };
    shipping: {
      address1: string;
      city: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      state: string;
    };
    shippingLines: {
      nodes: [{ methodTitle?: string }];
    };
    lineItems: {
      nodes: OrderItem[];
    };
  };
}

export interface OrdersItem {
  id: string;
  databaseId: string;
  orderNumber: string;
  total: string;
  currency: string;
  status: string;
  date: string;
  lineItems: {
    nodes: {
      product: {
        id: string;
        image: {
          id: string;
          sourceUrl: string;
        };
      };
    }[];
  };
}

export interface Orders {
  customer: {
    orders: {
      nodes: OrdersItem[];
    };
  };
}

export const GET_ORDERS_QUERY = gql`
  query getOrdersQuery {
    customer {
      orders(first: 1000) {
        nodes {
          id
          databaseId
          orderNumber
          total(format: FORMATTED)
          currency
          status
          date
          lineItems {
            nodes {
              product {
                id
                image {
                  id
                  sourceUrl(size: _300X210)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetOrderByIdVariables {
  id: string;
}

export interface GetOrderByIdResponse extends Order {}

export const getOrderById = gql`
  query getOrderById($id: ID) {
    order(id: $id, idType: DATABASE_ID) {
      id
      databaseId
      status
      subtotal
      total
      shippingTotal
      date
      dateCompleted
      orderNumber
      currency
      paymentMethod
      paymentMethodTitle
      billing {
        address1
        firstName
        lastName
        country
        postcode
        phone
        email
      }
      shipping {
        address1
        city
        email
        firstName
        lastName
        phone
        postcode
      }
      shippingLines {
        nodes {
          methodTitle
        }
      }
      lineItems {
        nodes {
          orderId
          quantity
          total
          variation {
            id
            sku
            onSale
            salePrice
            regularPrice
            stockQuantity
            attributes {
              nodes {
                id
                name
                value
              }
            }
          }
          product {
            id
            slug
            databaseId
            name
            onSale
            type
            image {
              id
              sourceUrl(size: MEDIUM_LARGE)
            }
            productBrands {
              nodes {
                id
                name
                slug
              }
            }
            ... on SimpleProduct {
              id
              name
              salePrice
              regularPrice
              stockQuantity
              stockStatus
            }
            ... on VariableProduct {
              id
              name
              salePrice
              regularPrice
              stockQuantity
            }
          }
        }
      }
    }
  }
`;
