import { useQuery } from "react-apollo";
import { CountriesResults } from "../../queries/checkoutQueries";
import { GET_AVAILABLE_COUNTRYS } from "../../queries/generalQueries";

export const useAvailableCountrys = () => {
  const shippingMethods = useQuery<CountriesResults>(GET_AVAILABLE_COUNTRYS);

  return {
    loading: shippingMethods.loading,
    error: shippingMethods.error,
    data: shippingMethods.data?.countries,
  };
};
