import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import MenuLoaders from "./MenuLoaders";
import { useRouter } from "../../../lib/hooks/useRouter";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-downn.svg";
import { useQuery } from "@apollo/react-hooks";
import * as shopQueries from "../../../queries/shop";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

import "./Menu.scss";
import { fields } from "../../../lib/translation/strings";

export const Menu = () => {
  const router = useRouter();
  const langCtx = useLanguageContext();
  const { t } = useTranslation();
  const prefix = langCtx.getLangPrefix();

  const { loading: finalCategoriesLoading, data } = useQuery<
    shopQueries.GetShopProductCategoriesResponse,
    shopQueries.GetShopProductCategoriesVariables
  >(shopQueries.getProductCategories);

  const finalCategories = data?.productCategories?.nodes || [];

  return (
    <div className={cn("Menu", finalCategoriesLoading && "Menu--loading")}>
      {finalCategoriesLoading ? (
        <MenuLoaders />
      ) : (
        <div className="Menu-items">
          {finalCategories?.map((c: any) => {
            const hasChildrens = c.children?.nodes?.length > 0;

            const handleClick = (event: any, isActive: boolean) => {
              const hasChildren = c.children?.nodes?.length > 0;

              if (hasChildren || isActive) {
                event.preventDefault();
              }
            };

            const isActiveCategories = router?.pathname?.includes(c.slug);

            return (
              <div
                key={c.id}
                onMouseOver={(e) => {
                  handleClick(e, isActiveCategories);
                }}
                role="button"
                className={cn(
                  "Menu__item",
                  !hasChildrens && "Menu__item--none"
                )}
              >
                <div
                  onClick={() => router.push(`${prefix}/shop/${c?.slug}`)}
                  className="Menu__item--title"
                >
                  <span
                    className={cn(
                      "Menu__item_inner",
                      isActiveCategories && "Menu__item--active"
                    )}
                  >
                    {t(c.slug)}
                  </span>
                  {hasChildrens && (
                    <ArrowDown
                      fill={isActiveCategories ? "#fe0001" : ""}
                      width={8}
                      height={8}
                    />
                  )}
                </div>
                <div className="Menu-dropdown">
                  <div className="Menu-dropdown-wrapper">
                    <ul className="Menu-dropdown__list">
                      {c?.children?.nodes?.map((item: any) => {
                        const isActiveSubCategories = router?.pathname?.includes(
                          item.slug
                        );

                        return (
                          <NavLink
                            key={item?.id}
                            className={cn(
                              "Menu-dropdown__list-item-child",
                              isActiveSubCategories &&
                                "Menu-dropdown__list-item-child--active"
                            )}
                            to={`${prefix}/shop/${c?.slug}/${item?.slug}`}
                          >
                            {t(item.slug)}
                          </NavLink>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            onClick={() => {
              router.push(`${prefix}/brands`);
            }}
            role="button"
            className="Menu__item"
          >
            <span
              className={cn(
                "Menu__item_inner Menu__item--title",
                "Menu__item--active"
              )}
            >
              {t(fields.brands)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
