import React from "react";
import { useTranslation } from "react-i18next";
import { Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { useHistory } from "react-router";
import { actions, formInputs, messages } from "../../lib/translation/strings";

// import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
// import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import { LoginFormik } from "../../lib/formik/useLoginFormik";
import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";
import { Button } from "../../components/shared/Button/Button";

import "./Login.scss";

interface Props {
  isLoading: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  // const authCtx = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();
  const langCtx = useLanguageContext();
  const prefix = langCtx.getLangPrefix();
  // const notificationCtx = useNotification();
  //
  // const loginError =
  //   authCtx.loginError &&
  //   typeof authCtx.loginError === "string" &&
  //   (authCtx.loginError.includes("invalid_email") ||
  //     authCtx?.loginError.includes("incorrect_password"));
  //
  // useEffect(() => {
  //   if (loginError) {
  //     notificationCtx.danger(t(errors.wrongEmailOrPassword), false);
  //   }
  // }, [loginError]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, formik } = props;

  const path = prefix + "/register";

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Input
          type="email"
          name="email"
          className="white"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={t(formInputs.email)}
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <FormFeedback>{formik.errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          name="password"
          type="password"
          className="white"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder={t(formInputs.password)}
          invalid={!!formik.errors.password && formik.touched.password}
        />
        <FormFeedback>{formik.errors.password}</FormFeedback>
      </FormGroup>
      <div className="d-flex flex-column align-items-center">
        <span>
          <ThemeLink to="/forgot-password" className="Login__wrapper-text">
            {t(messages.forgotPassword)}
          </ThemeLink>
        </span>

        <Button
          color="primary"
          type="submit"
          className="Login__submit w-100-mobile"
        >
          {isLoading ? t(actions.loggingIn) + "..." : t(actions.login)}
        </Button>

        <span className="Login__border" />

        <Button
          color="primary"
          className="Login__register w-100-mobile"
          onClick={() => history.push(path)}
        >
          {t(actions.register)}
        </Button>
        {/* 
        <div className="Login__links">
          <ThemeLink to="/register" className="Login__wrapper-text">
            Krijoni llogarinë!
          </ThemeLink>
        </div> */}
      </div>
    </Form>
  );
};
