import React from "react";
import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";

import { useNotification } from "../../../lib/context/NotificationContext/NotificationContext";

interface Props {
  error?: ApolloError | ReadonlyArray<GraphQLError>;
  errorComponent?: JSX.Element;
  children: React.ReactNode;
}

export const ApolloErrorGuard = (props: Props) => {
  const notificationCtx = useNotification();

  if (!props.error) {
    return <>{props.children}</>;
  }

  if (props.errorComponent) {
    return <>{props.errorComponent}</>;
  }

  notificationCtx.danger(props.error.toString());

  return <></>;
};
