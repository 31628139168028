import React from "react";
import { Logo } from "../Logo/Logo";
import { UNavigation } from "../UserNavigation/UNavigation";
import { Search } from "../Search/Search";

import "./InnerHeader.scss";

export const InnerHeader = () => {
  return (
    <div className="InnerHeader">
      <div className="InnerHeader__wrapper">
        <div className="InnerHeader__wrapper-left">
          <Logo />
        </div>
        <div className="InnerHeader__wrapper-middle">
          <Search />
        </div>
        <div className="InnerHeader__wrapper-right">
          <UNavigation />
        </div>
      </div>
    </div>
  );
};
