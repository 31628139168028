import React from "react";
import shortid from "shortid";

interface Props {
  length: number;
  component: React.ReactType;
}

export const BulkLoader = (props: Props) => {
  const LoaderComponent = props.component;

  const items = Array.from({ length: props.length }, (v, i) => {
    return { key: shortid.generate(), value: i + 1 };
  });

  return (
    <>
      {items.map((item) => (
        <LoaderComponent key={`${item.key}-loader`} />
      ))}
    </>
  );
};
