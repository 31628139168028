import React, { useContext } from "react";

export interface MenuContextType {
  isOpened: boolean;
  toggleSidebar: () => void;
  openSidebar: () => void;
  closeSidebar: () => void;
}

const defaultMenuContext: MenuContextType = {
  isOpened: false,
  toggleSidebar: () => {},
  openSidebar: () => {},
  closeSidebar: () => {}
};

export const MenuContext = React.createContext<MenuContextType>(
  defaultMenuContext
);

export function useMenuContext() {
  return useContext(MenuContext);
}
