import React from "react";
import GoogleMapReact from "google-map-react";

import styles from "./GoogleMapsStyles.json";

import "./GoogleMaps.scss";

interface Props {
  lat: string;
  lng: string;
  apiKey: string;
}

export const GoogleMaps = (props: Props) => {
  const lat = parseFloat(props.lat);
  const lng = parseFloat(props.lng);
  // const [state, setState] = useState({
  //   lng: 0,
  //   lat: 0,
  // });

  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat, lng },
      map,
      icon: "",
    });

    return marker;
  };

  // const getLocation = () => {
  //   if (!navigator.geolocation) {
  //     setStatus("Geolocation is not supported by your browser");
  //   } else {
  //     setStatus("Locating...");
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setStatus("");
  //         setState({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       () => {
  //         setStatus("Unable to retrieve your location");
  //       }
  //     );
  //   }
  // };

  return (
    <div className="GoogleMaps">
      <GoogleMapReact
        options={{
          styles: styles,
        }}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: props.apiKey }}
        defaultCenter={{
          lat,
          lng,
        }}
        defaultZoom={15}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};
