import React, { useState } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { messages } from "../../lib/translation/strings";
import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";
import { ProductSingleCarouselItem } from "./ProductSingleCarouselItem/ProductSingleCarouselItem";
import { Image } from "../shared/Image/Image";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { Icon } from "../shared/Icon/Icon";

import "./ProductSingleCarousel.scss";

export const ProductSingleCarousel = () => {
  const { t } = useTranslation();
  const { product, selectedAttributes } = useProductSingleContext();
  const {
    getProductImages,
    getProductVariationSlug,
  } = useProductSingleHelpers();

  const [slider1, setSlider1] = useState<any>();
  const [slider2, setSlider2] = useState<any>();

  const sliderImages = getProductImages(product);
  const variationSlug = getProductVariationSlug(
    product?.variations?.nodes,
    selectedAttributes?.find((f: any) => f.attribute === "pa_ngjyra")
      ?.value as any
  );

  React.useEffect(() => {
    if (variationSlug === undefined) {
      return;
    }

    const img = sliderImages?.find((item: any) => item.slug === variationSlug);

    if (!img) {
      return;
    }

    const imgIndex = sliderImages?.indexOf(img);

    if (imgIndex === undefined) {
      return;
    }

    slider1?.slickGoTo(imgIndex);
    slider2?.slickGoTo(imgIndex);
  }, [variationSlug, sliderImages, slider1, slider2]);

  const settingsMain = {
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    swipeToSlide: false,
    asNavFor: slider2,
  };

  const settingsThumbs = {
    initialSlide: 0,
    slidesToShow: sliderImages.length,
    slidesToScroll: 1,
    asNavFor: slider1,
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  // const showOutOfStockOverlay = () => {
  //   // Check if it's a variable product but hasn't selected the options
  //   if (product.type === "VARIABLE" && variation === undefined) {
  //     return false;
  //   }

  //   // By default return based on stock status of product or product variation
  //   return !inStock;
  // };

  const isOutOfStock =
    product.stockStatus === "OUT_OF_STOCK" || product.stockQuantity === 0;

  return (
    <div
      className={cs("ProductSingleCarousel", {
        "ProductSingleCarousel--out_of_stock": isOutOfStock,
      })}
    >
      {isOutOfStock && (
        <div className="ProductSingleCarousel__no-stock">
          <span>
            <Icon icon="alert-octagon" style={{ marginRight: 10 }} />{" "}
            {t(messages.soldOut)}
          </span>
        </div>
      )}
      <div className="ProductSingleCarousel__image_wrapper">
        <div className="ProductSingleCarousel__image">
          {!sliderImages || sliderImages?.length === 0 ? (
            <Image />
          ) : (
            <Slider {...settingsMain} ref={(slider) => setSlider1(slider)}>
              {sliderImages.map(
                (image: { sourceUrl: string }, index: number) => {
                  return (
                    <ProductSingleCarouselItem
                      image={image.sourceUrl}
                      key={`${image.sourceUrl}-${index}`}
                    />
                  );
                }
              )}
            </Slider>
          )}
        </div>
        {sliderImages && (
          <Slider
            {...settingsThumbs}
            className="ProductSingleCarousel__thumbs"
            ref={(slider) => setSlider2(slider)}
          >
            {sliderImages.map((image: { sourceUrl: string }, index: number) => {
              return (
                <div
                  key={`${image.sourceUrl}-${index}`}
                  className="ProductSingleCarousel__thumbs__item"
                >
                  <Image src={image.sourceUrl} alt={product.name} />
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};
