import React, { useEffect, useState } from "react";
import { SidebarContext, SidebarContextType } from "./SidebarContext";
import { useWindowWidth } from "../../hooks/useWindowWidth";

interface Props {
  children: React.ReactNode;
}

export const SidebarContextProvider = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (windowWidth <= 768 && isOpened) {
      document.body.style.overflow = "hidden";
      document.getElementById("App")?.classList.add("App--show-backdrop");
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById("App")?.classList.remove("App--show-backdrop");
    };
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const openSidebar = () => {
    setIsOpened(true);
  };

  const closeSidebar = () => {
    setIsOpened(false);
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const context: SidebarContextType = {
    isOpened,
    closeSidebar,
    openSidebar,
    toggleSidebar
  };

  return (
    <SidebarContext.Provider value={context}>
      {props.children}
    </SidebarContext.Provider>
  );
};
