import React from "react";
import { InnerHeader } from "./InnerHeader/InnerHeader";
import { Menu } from "./Menu/Menu";
import { Search } from "./Search/Search";
import { Container } from "../../components/Container/Container";

import "./Header.scss";

export const Header = () => {
  return (
    <div className="Header">
      <Container>
        <div className="Header__wrapper">
          <InnerHeader />
          <Menu />
        </div>
        <Search className="Header-search-mobile" />
      </Container>
    </div>
  );
};
