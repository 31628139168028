import React from "react";
import cs from "classnames";
import { Container } from "../../components/Container/Container";

import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";

import "./DefaultPage.scss";
import { Image } from "../../components/shared/Image/Image";

interface Props {
  title: string;
  content: string;
  excerpt?: string;
  srcSet?: string;
  featuredImage?: string;
}

export const DefaultPage = (props: Props) => {
  const classes = cs("DefaultPage", {
    "DefaultPage--no_thumb": !props.featuredImage,
  });

  return (
    <div className={classes}>
      <ThemeContainer>
        <HeadingTitle title={props.title} />

        {props.featuredImage && (
          <div className="DefaultPage__thumb">
            <Image
              src={props.featuredImage}
              srcSet={props.srcSet}
              alt={props.title}
            />
          </div>
        )}
      </ThemeContainer>

      <div className="DefaultPage__content mb-5">
        <Container>
          <>
            <ThemeContainer>
              <div className="DefaultPage__heading">
                {props.excerpt && (
                  <div className="DefaultPage__heading__excerpt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.excerpt,
                      }}
                    />
                  </div>
                )}
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: props.content,
                }}
              />
            </ThemeContainer>
          </>
        </Container>
      </div>
    </div>
  );
};
