import React from "react";
import { useMutation } from "react-apollo";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useProfileAccountFormik } from "../../../lib/formik/profile/useProfileAccountFormik";
import { useNotification } from "../../../lib/context/NotificationContext/NotificationContext";

import { formMessages } from "../../../lib/translation/strings";

import { UPDATE_PROFILE } from "../../../queries/mutations/userMutations";

import { ProfileAccountForm } from "./ProfileAccountForm";

import "./ProfileAccount.scss";

export interface ProfileAccountFields {
  email: string;
  username: string;
  lastName: string;
  firstName: string;
}

export const ProfileAccount = () => {
  const authCtx = useAuthContext();
  const notificationCtx = useNotification();
  const [updateProfileMutation, { loading }] = useMutation(UPDATE_PROFILE);

  const formik = useProfileAccountFormik({
    onSubmit: async (values) => {
      if (!formik.dirty || !authCtx.user) {
        return;
      }

      try {
        await updateProfileMutation({
          variables: {
            id: authCtx.user?.id,
            firstName: values.firstName,
            lastName: values.lastName
          }
        });

        notificationCtx.success(formMessages.profileUpdated);

        authCtx.updateUser({
          ...authCtx.user,
          firstName: values.firstName,
          lastName: values.lastName
        });
      } catch (e) {
        console.error(e);
        notificationCtx.danger(formMessages.profileNotUpdated);
      }
    }
  });

  return (
    <div className="ProfileAccount">
      <ProfileAccountForm
        formik={formik}
        loading={loading}
        disable={!formik.dirty}
      />
    </div>
  );
};
