import React, { useRef, useState } from "react";
import cs from "classnames";

import { Spinner } from "reactstrap";
// import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { errors } from "../../../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../shared/Icon/Icon";
import "./ShopSelect.scss";

export interface ShopSelectOptions {
  value: string;
  label: string | React.ReactNode;
}

interface Props {
  initialOpen?: boolean;
  label: string;
  className?: string;
  loading?: boolean;
  relative?: boolean;
  relativeIcon?: boolean;
  disableMultiselect?: boolean;
  hideIfNoOptions?: boolean;
  closeOnSelect?: boolean;
  showSelectedOption?: boolean;
  selected?: ShopSelectOptions[];
  options?: ShopSelectOptions[];
  onSelect: (option?: ShopSelectOptions) => void;
}

export const ShopSelect = (props: Props) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [opened, setOpened] = useState(props.initialOpen || false);

  // useOutsideClickDetection(ref, () => setOpened(false));

  // useEffect(() => {
  //   if (props.initialOpen) {
  //     setOpened(true);
  //   }
  // }, [props.initialOpen]);

  // useEffectWithoutInitialRender(() => {
  //   if (props.disableMultiselect) {
  //     props.onSelect(selected[0]);
  //   } else {
  //     props.onSelect(selected);
  //   }
  //   closeDropdownIfRequired();
  // }, [selected]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSelect = (item?: ShopSelectOptions) => {
    // if (props.disableMultiselect) {
    //   props.onSelect(items[0]);
    // } else {
    //   props.onSelect(items);
    // }

    props.onSelect(item);
    closeDropdownIfRequired();
  };

  const handleOnClick = () => {
    setOpened(!opened);
  };

  const closeDropdownIfRequired = () => {
    if (props.closeOnSelect) {
      setOpened(false);
    }
  };

  const handleOnItemSelect = (option: ShopSelectOptions) => {
    if (props.selected?.includes(option) && props.disableMultiselect) {
      handleOnSelect(undefined);
      return;
    }

    handleOnSelect(option);

    // // Check if selected items is empty and this is first selected option;
    // if (selected.length === 0) {
    //   // setSelected([option]);
    //   handleOnSelect([option]);
    //   return;
    // }
    //
    // // Filter out if option is already selected
    // if (selected.includes(option)) {
    //   // setSelected(selected.filter(o => o.value !== option.value));
    //   handleOnSelect(selected.filter(o => o.value !== option.value));
    //   return;
    // }
    //
    // // If multi select is disabled, override selected item, otherwise add selected items to array
    // if (props.disableMultiselect) {
    //   // setSelected([option]);
    //   handleOnSelect([option]);
    // } else {
    //   // setSelected([...selected, option]);
    //   handleOnSelect([option]);
    // }
  };

  if (props.hideIfNoOptions && props.options && props.options.length <= 0) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cs(
        "ShopSelect",
        opened && "ShopSelect--opened",
        props.loading && "ShopSelect--loading",
        props.relative && "ShopSelect--relative",
        props.relativeIcon && "ShopSelect--relative-icon",
        props.selected &&
          props.selected.length > 0 &&
          "ShopSelect--has-selected",
        props.className
      )}
    >
      <button
        disabled={props.loading}
        data-opened={opened}
        className="ShopCategories__title"
        // className="ShopSelect__label"
        onClick={handleOnClick}
      >
        {t(props.label)}

        {/*{props.disableMultiselect && props.showSelectedOption && (*/}
        {/*  <span className="ShopSelect__label_selected">*/}
        {/*    {props.selected && props.selected[0] && props.selected[0]?.label}*/}
        {/*  </span>*/}
        {/*)}*/}

        {props.loading ? (
          <span className="ShopSelect__label_icon">
            <Spinner className="ShopSelect__label_icon_spinner" />
          </span>
        ) : (
          <span data-opened={opened} className="ShopSelect__label_icon">
            <Icon icon="chevron-down" />
          </span>
        )}
      </button>

      {opened && (
        <div className="ShopSelect__dropdown" data-opened={opened}>
          <ul className="ShopSelect__dropdown_list">
            {!props.options || props.options.length === 0 ? (
              <span>{t(errors.noOptions)}</span>
            ) : (
              props.options?.map((option, index) => {
                let items = props.selected;

                const target =
                  items && Array.isArray(items)
                    ? items.find((o) => {
                        return o.value === option.value;
                      })
                    : null;

                return (
                  <li
                    key={`${option.value}-${index}`}
                    data-selected={target?.value === option.value}
                    className="ShopSelect__dropdown_list_item"
                    onClick={() => handleOnItemSelect(option)}
                  >
                    {option.label}
                  </li>
                );
              })
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
