import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { useApolloClient } from "react-apollo";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { LanguageContext, LanguageContextType } from "./LanguageContext";
import { transformTranslationsResults } from "./translationHelpers";

import {
  GET_TRANSLATIONS_QUERY,
  TranslationsResults,
} from "../../../queries/translationsQueries";
import { Logo } from "../../../layout/Header/Logo/Logo";

interface Props {
  children: React.ReactNode;
}

export interface Translation {
  [key: string]: string;
}

export interface Translations {
  [locale: string]: Translation;
}

interface LanguageContextProviderState {
  loading: boolean;
  error?: string | { [key: string]: string };
}

export const LanguageContextProvider = (props: Props) => {
  const client = useApolloClient();

  const [state, setState] = useState<LanguageContextProviderState>({
    loading: true,
    error: undefined,
  });

  useEffect(() => {
    loadTranslations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadTranslations = async () => {
    try {
      const res = await client.query({
        query: GET_TRANSLATIONS_QUERY,
      });

      handleI18nTranslations(res.data);

      setState({
        ...state,
        loading: false,
      });
    } catch (e) {
      setState({
        ...state,
        error: e,
        loading: false,
      });
    }
  };

  const getLanguagePrefix = () => {
    return !i18n.language || i18n.language === "sq" ? "" : `/${i18n.language}`;
  };

  const handleI18nTranslations = (translations: TranslationsResults) => {
    const transformedStrings = transformTranslationsResults(translations);

    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translations: transformedStrings.en,
          },
          sq: {
            translations: transformedStrings.sq,
          },
        },
        fallbackLng: ["sq"],
        whitelist: ["en", "sq"],
        defaultNS: "translations",
        detection: {
          order: ["path"],
          checkWhitelist: true,
          lookupFromPathIndex: 0,
        },
        interpolation: {
          escapeValue: false,
          formatSeparator: ".",
        },
      });
  };

  const context: LanguageContextType = {
    loading: state.loading,
    error: state.error,
    getLangPrefix: getLanguagePrefix,
  };

  if (state.loading) {
    return (
      <div className="App App--loading">
        <Logo noLink />
      </div>
    );
  }

  return (
    <LanguageContext.Provider value={context}>
      {props.children}
    </LanguageContext.Provider>
  );
};
