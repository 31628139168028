import React from "react"
import ContentLoader from "react-content-loader"

export const OrderSingleLoader = () => (
    <ContentLoader
        speed={2}
        width={476}
        height={144}
        viewBox="0 0 476 144"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="202" y="50" rx="3" ry="3" width="88" height="6" />
        <rect x="202" y="68" rx="3" ry="3" width="160" height="6" />
        <rect x="202" y="86" rx="3" ry="3" width="210" height="6" />
        <rect x="202" y="105" rx="3" ry="3" width="210" height="6" />
        <rect x="202" y="125" rx="3" ry="3" width="88" height="6" />
        <rect x="5" y="38" rx="0" ry="0" width="174" height="109" />
        <rect x="5" y="16" rx="3" ry="3" width="88" height="6" />
    </ContentLoader>
)