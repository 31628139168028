import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { fields } from "../../lib/translation/strings";

import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";
import { useHistory } from "react-router-dom";

import "./ProfileSidebar.scss";

export const ProfileSidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pn = history.location.pathname;

  return (
    <div className="ProfileSidebar">
      <ul className="HomeTabs__tabs">
        <li
          className={cn(
            "HomeTabs__tab",
            pn === "/my-profile" && "HomeTabs__tab--active"
          )}
        >
          <ThemeLink exact to={`/my-profile`}>
            {t(fields.myAccount)}
          </ThemeLink>
        </li>
        <li
          className={cn(
            "HomeTabs__tab",
            pn === "/my-profile/address" && "HomeTabs__tab--active"
          )}
        >
          <ThemeLink to={`/my-profile/address`}>{t(fields.address)}</ThemeLink>
        </li>
        <li
          className={cn(
            "HomeTabs__tab",
            pn === "/my-profile/privacy" && "HomeTabs__tab--active"
          )}
        >
          <ThemeLink to={`/my-profile/privacy`}>{t(fields.privacy)}</ThemeLink>
        </li>
        <li
          className={cn(
            "HomeTabs__tab",
            pn === "/my-profile/orders" && "HomeTabs__tab--active"
          )}
        >
          <ThemeLink to={`/my-profile/orders`}>{t(fields.myOrders)}</ThemeLink>
        </li>
      </ul>
    </div>
  );
};
