import React from "react";
import { Container } from "reactstrap";
import { ProfileSidebar } from "./ProfileSidebar";
import { ProfileContent } from "./ProfileContent";

import "./Profile.scss";

export const Profile = () => {
  return (
    <div className="Profile mt-heading">
      <Container className="p-0-mobile">
        <ProfileSidebar />
        <ProfileContent />
      </Container>
    </div>
  );
};
