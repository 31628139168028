import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useHistory } from "react-router";
import { ReactComponent as Placeholder } from "../../assets/icons/placeholder.svg";
import { ReactComponent as Smartphone } from "../../assets/icons/smartphone.svg";
import { ReactComponent as Money } from "../../assets/icons/money.svg";
import { ReactComponent as Truck } from "../../assets/icons/delivery-truck.svg";
import { ReactComponent as Trophy } from "../../assets/icons/trophy.svg";
import { FeedbackModal } from "../../components/Feedback/FeedbackModal";
import { Icon } from "../../components/shared/Icon/Icon";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import { removeHTMLTags } from "../../lib/helpers/removeHTMLTags";
import { actions, fields, messages } from "../../lib/translation/strings";
import { GET_FOOTER } from "../../queries/generalQueries";

import "./Footer.scss";

export interface Feedback {
  feedback: string;
  message: string;
  review: number;
}

export const Footer = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const lngCtx = useLanguageContext();
  const prefix = lngCtx.getLangPrefix();
  const { data, loading, error } = useQuery(GET_FOOTER);

  const footerData = data?.generalOptions?.generalOptions?.footer ?? {};

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div className="Footer">
      {(history.location.pathname === "/" ||
        history.location.pathname === "/en/") && (
        <div className="Footer__desktop">
          <Container>
            <div className="Footer__desktop_wrapper">
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Truck />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>{t(messages.orderOnline)} </span>
                    {/* ONLINE */}
                  </h1>
                  <span>{t(messages.transportKosova)}</span>
                </div>
              </div>
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Money />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>{t(messages.payAfterOrder)}</span>
                  </h1>
                  <span>{t(messages.toTheDoor)}</span>
                </div>
              </div>
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Trophy />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>{t(messages.granteedQuality)}</span>
                  </h1>
                  <span>{t(messages.highQuality)}</span>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
      <Container>
        <div className="Footer__items">
          <ul className="Footer__list">
            <li className="Footer__list-head">{t(fields.info)}</li>
            <li>
              <Link to={`${prefix}/`}>{t(actions.home)}</Link>
            </li>
            <li>
              <Link to={`${prefix}/contact`}>{t(actions.contact)}</Link>
            </li>
            {/* {authCtx.isAuthenticated && (
              <li>
                <Link to={`${prefix}/jobs`}>{t(actions.jobs)}</Link>
              </li>
            )} */}
            <li>
              <Link to={`${prefix}/page/3`}>{t(actions.privacyPolicy)}</Link>
            </li>
            <li onClick={() => setOpen(true)}>
              <span>{t(actions.feedback)}</span>
            </li>
          </ul>

          <ul className="Footer__list">
            <li className="Footer__list-head">{t(actions.contact)}</li>
            <li>
              <a href={`mailto:${footerData?.email}`}>
                {t(messages.contactFooter, { email: footerData?.email })}
              </a>
            </li>
            <li className="Footer__list-location">
              <Placeholder width={24} height={32} />
              <a href={`${prefix}/contact`}>
                {removeHTMLTags(
                  i18n.language === "en"
                    ? footerData?.addressEn
                    : footerData?.address
                )}
              </a>
            </li>
            <li className="Footer__list-phone">
              <Smartphone width={24} height={32} />
              <a href={`tel:+${footerData?.phone}`}>+{footerData?.phone}</a>
            </li>
          </ul>
          <ul className="Footer__list">
            <li className="Footer__list-head">{t(fields.socialMedia)}</li>
            <li style={{ display: "flex" }}>
              <a
                href={footerData?.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon style={{ width: 24, height: 32 }} icon="facebook" />
              </a>
              <a
                href={footerData?.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Icon style={{ width: 24, height: 32 }} icon="instagram" />
              </a>
              <a
                href={footerData?.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Icon style={{ width: 24, height: 32 }} icon="youtube" />
              </a>
              <a
                href={footerData?.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Icon style={{ width: 24, height: 32 }} icon="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </Container>
      <div className="Footer__copyright">
        <span>
          {t(messages.copyRight)} © {new Date().getFullYear()} Led-Com.{" "}
          {t(messages.rightReserved)}.
        </span>
      </div>
      <FeedbackModal open={open} toggle={() => setOpen(false)} />
    </div>
  );
};
