import React from "react";
import ContentLoader from "react-content-loader";

export const WishlistLoader = () => {
  return (
    <div className="WishlistLoader">
      <ContentLoader
        speed={2}
        width={300}
        height={282}
        viewBox="0 0 300 282"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="ProductsLoader"
      >
        <rect x="200" y="0" width="100" height="15" className="mobilenone" />
        <rect x="0" y="30" width="300" height="282" />
      </ContentLoader>
    </div>
  );
};
