import React from "react";
import { useQuery } from "react-apollo";
import Slider from "react-slick";
import {
  GET_TYPE_A_CAMPAIGNS,
  CampaignTypeAPost,
} from "../../../queries/campaignsQueries";
import { SlideLoader } from "./SlideLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { Icon } from "../../shared/Icon/Icon";

import "./Slide.scss";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 45,
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "transparent",
        borderRadius: 20,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon
        icon="chevron-right"
        style={{ color: "rgba(238, 238, 238, 0.8)" }}
      />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 45,
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "transparent",
        borderRadius: 20,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "rgba(238, 238, 238, 0.8)" }} />
    </span>
  );
};

export const Slide = () => {
  const slider = useQuery(GET_TYPE_A_CAMPAIGNS);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 767;

  const sliderData =
    slider.data?.generalOptions?.generalOptions?.homeAds
      ?.a1BannerSliderCampaigns ?? [];

  const settings = {
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    fade: false,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />,
  };

  return (
    <div className="Slide">
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<SlideLoader />}
      >
        <ApolloErrorGuard error={slider.error}>
          <Slider {...settings}>
            {sliderData?.map((item: CampaignTypeAPost) => {
              const imgLink = isMobile
                ? item?.campaignResponsiveImage?.responsiveImage?.mediaItemUrl
                : item?.featuredImage?.node?.mediaItemUrl;
              console.log(
                item?.campaignResponsiveImage?.responsiveImage?.mediaItemUrl
              );
              return (
                <div key={item.id} className="Slide__item">
                  <img src={imgLink} alt="" />
                  {/* eslint-disable-next-line */}
                  <a
                    href={item?.campaignsOptions?.link}
                    className="Slide__content"
                  />
                </div>
              );
            })}
          </Slider>
        </ApolloErrorGuard>
      </HandleLoadingState>

      {/* <Button
        color="primary"
        className="Slide__btn-down"
        onClick={handleOnScrollDown}
      >
        <Icon icon="chevron-down" />
      </Button> */}
    </div>
  );
};
