import { ProductSingleVariationProps } from "../../../queries/archive";

export const getVariationAttributeValue = (
  variation: ProductSingleVariationProps,
  attr_name: string
) => {
  return (
    variation.node.attributes.nodes.find((attr: any) => attr.name === attr_name)
      ?.value || null
  );
};
