import React from "react";
import { toast, TypeOptions } from "react-toastify";
import {
  NotificationContext,
  NotificationContextType
} from "./NotificationContext";
import { formatGraphqlErrors } from "../../helpers/formatGraphqlErrors";
import { useRouter } from "../../hooks/useRouter";

interface NotificationContextProviderProps {
  children: React.ReactNode | null;
}

export interface NotificationState {
  type: TypeOptions;
  message: string;
  className?: string;
  autoClose: boolean;
}

export const NotificationContextProvider = (
  props: NotificationContextProviderProps
) => {
  const router = useRouter();

  React.useEffect(() => {
    toast.dismiss();
  }, [router.location]);

  const warning = (message: string, autoClose: boolean = false) => {
    add("warning", message, autoClose);
  };

  const success = (message: string, autoClose: boolean = false) => {
    add("success", message, autoClose);
  };

  const danger = (message: string, autoClose: boolean = false) => {
    const formatedMessage = formatGraphqlErrors(message);
    add("error", formatedMessage, autoClose);
  };

  const add = (type: TypeOptions, message: string, autoClose: boolean) => {
    toast(message, {
      type: type as any,
      autoClose: !autoClose ? false : 10000
    });
  };

  // TODO: refactor
  const remove = () => {
    toast.dismiss();
  };
  const removeAll = () => {
    toast.dismiss();
  };

  const context: NotificationContextType = {
    warning,
    success,
    danger,
    remove,
    removeAll
  };

  return (
    <NotificationContext.Provider value={context}>
      {props.children}
    </NotificationContext.Provider>
  );
};
