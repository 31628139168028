import gql from "graphql-tag";
import { Coupon } from "../types/generalTypes";
import { ProductNode } from "../types/productTypes";
import { VARIABLE_PRODUCT_FIELDS } from "./archive";

export interface CartNode {
  [key: string]: any;
  key: string;
  quantity: number;
  variation: {
    [key: string]: any;
    node: {
      id: string;
      sku: string;
      onSale: boolean;
      salePrice: string;
      regularPrice: string;
      stockQuantity: number;
      image: {
        srcSet: string;
        mediaItemUrl: string;
      };
      attributes: {
        nodes: [
          {
            id: string;
            name: string;
            value: string;
          },
          {
            id: string;
            name: string;
            value: string;
          }
        ];
      };
    };
  };
  product: ProductNode;
}

export interface GetCartResponse {
  cart: {
    isEmpty: boolean;
    subtotal?: string;
    total: string;
    cartPrice: string;
    discountTotal: string;
    shippingTotal: string;
    chosenShippingMethod: string;
    totalWithoutShipping: string;
    appliedCoupons: {
      nodes: Coupon[];
    };
    contents: {
      itemCount: number;
      nodes: CartNode[];
    };
  };
}

export const getCart = gql`
  query getCart {
    cart {
      isEmpty
      subtotal
      total
      discountTotal
      shippingTotal
      chosenShippingMethod
      appliedCoupons {
        nodes {
          id
          code
          amount
          discountType
        }
      }
      contents {
        itemCount
        nodes {
          key
          quantity
          cartPrice
          variation {
            node {
              id
              sku
              onSale
              salePrice
              regularPrice
              stockQuantity
              image {
                mediaItemUrl
                srcSet(size: LARGE)
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
          product {
            node {
              id
              slug
              databaseId
              name
              onSale
              type
              image {
                id
                mediaItemUrl
                srcSet(size: LARGE)
              }
              productBrands {
                nodes {
                  id
                  name
                  slug
                }
              }

              ... on SimpleProduct {
                id
                name
                salePrice
                regularPrice
                stockQuantity
                stockStatus
                localAttributes {
                  nodes {
                    name
                    options
                    id
                  }
                }
              }
              ... on VariableProduct {
                ...VariableProductFields
              }
            }
          }
        }
      }
    }
  }
  ${VARIABLE_PRODUCT_FIELDS}
`;
