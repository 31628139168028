import React from "react";
import { useQuery } from "react-apollo";
import { Container } from "reactstrap";
import cs from "classnames";
import { useRouter } from "../../lib/hooks/useRouter";
import * as brandsQueries from "../../queries/brands";

import { BrandsList } from "../../components/Brands/BrandsList";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";

import "./Brands.scss";

export const Brands = () => {
  const router = useRouter();

  const { loading, data, error } = useQuery(brandsQueries.getHomeBrands);

  const items: brandsQueries.Brand[] =
    data?.generalOptions?.generalOptions?.randomBrands?.randomBrands ?? [];

  // const handleLoadMore = () => {
  //   setIsLoadingMore(true);

  //   fetchMore({
  //     variables: {
  //       limit: postsLimit,
  //       after: data?.productBrands?.pageInfo?.endCursor
  //     },

  //     updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
  //       const newNodes = fetchMoreResult.productBrands.nodes;
  //       const pageInfo = fetchMoreResult.productBrands.pageInfo;

  //       setIsLoadingMore(false);

  //       if (!newNodes.length) {
  //         return previousResult;
  //       }

  //       return {
  //         productBrands: {
  //           __typename: previousResult.productBrands.__typename,
  //           nodes: [...previousResult.productBrands.nodes, ...newNodes],
  //           pageInfo
  //         }
  //       };
  //     }
  //   });
  // };

  // const handleSearchChange = debounce((value: string) => {
  //   refetch({
  //     limit: postsLimit,
  //     search: value
  //   });
  // }, 400);

  // const handleSelectChange = (value: string) => {
  //   refetch({
  //     limit: postsLimit,
  //     firstLetter: value
  //   });
  // };

  // const hasNextPage = !!data?.productBrands?.pageInfo?.hasNextPage;

  return (
    <>
      <div
        className={cs("Brands", router.pathname === "/brands" && "Brands--mt")}
      >
        <Container className="p-0-mobile">
          <ApolloErrorGuard error={error}>
            <BrandsList
              onLoadMore={() => {}}
              loading={loading}
              brands={items || []}
            />
          </ApolloErrorGuard>
        </Container>
      </div>
    </>
  );
};
