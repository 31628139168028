import React from "react";
import { Button } from "../../components/shared/Button/Button";
import { Icon } from "../../components/shared/Icon/Icon";
import "./Page404.scss";

export const Page404 = () => {
  return (
    <div className="Page404">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Page404-wrapper">
              <Icon icon="alert-circle" className="Page404-alert" />
              <p>Faqja që keni kërkuar nuk egziston</p>
              <Button to="/" color="primary">
                <Icon icon="home" className="mr-2" />
                Kthehu në faqen kryesore{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
