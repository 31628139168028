import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { formValidation } from "../translation/strings";
import { ResetPasswordFields } from "../../pages/ResetPassword/ResetPassword";

interface UseResetPasswordFormOptions {
  initialValues: ResetPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ResetPasswordFields>
  ) => Promise<any>;
}

export const useResetPasswordFormik = (
  options: UseResetPasswordFormOptions
) => {
  const {t} = useTranslation();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required(t(formValidation.password)),
    passwordConfirm: Yup.string()
      .required(t(formValidation.password))
      .oneOf([Yup.ref("password")], t(formValidation.passwordNotMatch))
  });

  return useFormik({
    initialValues: {
      ...options.initialValues
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type ResetPasswordFormik = ReturnType<typeof useResetPasswordFormik>;
