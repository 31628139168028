import React from "react";
import { RedirectProps, Redirect } from "react-router-dom";
// import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

export const ThemeRedirect = (props: RedirectProps) => {
  // const langCtx = useLanguageContext();
  // const prefix = langCtx.getLangPrefix();

  return <Redirect {...props} to={props.to} />;
};
