import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeLink } from "../shared/ThemeLink/ThemeLink";
import { ProductProps } from "../../types/productTypes";
import { Spinner } from "reactstrap";
import { actions, errors, messages } from "../../lib/translation/strings";
import { Image } from "../shared/Image/Image";
import { RemoveItemButton } from "../RemoveItemButton/RemoveItemButton";
import { WishListButton } from "../shared/WishListButton/WishListButton";
// import { ProductWatermark } from "./ProductItemWatermark";
import { getProductImages } from "../../pages/SingleProduct/lib/helpers/getProductImages";
import cs from "classnames";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { ProductPrices } from "../shared/ProductPrices/ProductPrices";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { ReactComponent as ShoppingCart } from "../../assets/icons/shopping-cart.svg";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

import "./ListOfProducts.scss";

interface Props {
  big?: boolean;
  product?: ProductProps;
  noWishlistButton?: boolean;
  onRemove?: () => void;
  gridView?: "ONE" | "LIST";
}

export const Product = (props: Props) => {
  const { t } = useTranslation();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);
  const menuCtx = useMenuSidebar();
  const sliderImages = getProductImages(props.product);
  const { items, addProduct } = useCartContext();
  const [selectImg, setSelectImg] = useState(sliderImages[0]?.slug);
  const { renderProductFieldsByLanguage } = useProductSingleHelpers();

  const thumbnail = props.big
    ? props.product?.image?.mediaItemUrl
    : props.product?.image?.sourceUrl;

  // const watermark = props.product?.productWatermarks?.nodes[0]?.name;

  const salePrice = props.product?.regularPrice
    ? parseFloat(props.product?.salePrice)
    : 0;
  const regularPrice = props.product?.regularPrice
    ? parseFloat(props.product?.regularPrice)
    : 0;

  const discountedPercentage =
    salePrice !== 0 ? ((regularPrice - salePrice) / regularPrice) * 100 : 0;

  const productStockStatus = () => {
    if (!props.product) {
      return;
    }

    if (props.product?.type === "SIMPLE") {
      return props.product.stockStatus === "OUT_OF_STOCK" ? 0 : 1;
    }

    const variationsStock = props.product.variations?.nodes?.filter(
      (vs) => vs.stockQuantity && vs.stockQuantity >= 1
    );

    return variationsStock?.length;
  };

  const stockStatus = productStockStatus();

  const img = sliderImages?.find((item: any) => item?.slug === selectImg);

  const getItemFromCart = () => {
    const cartItem =
      items?.filter(
        (item: any) =>
          item.product.node?.databaseId === props.product?.databaseId
      ) || [];

    if (cartItem.length === 0) {
      return;
    }

    return cartItem[0] as any;
  };

  const handleAddToCart = async () => {
    const cartItem = getItemFromCart();

    if (stockStatus === 0) {
      notification.warning(t(errors.productNotInStock)); //This product is out of stock.
      return;
    }

    if (cartItem && cartItem.quantity + props.product?.stockQuantity > 1) {
      notification.warning(
        `Ju nuk mund ta shtoni atë shumë në shportë, ju tashmë keni ${cartItem?.quantity} sasi në shportën tuaj.`
      );
      return;
    }

    try {
      setLoading(true);

      await addProduct(props.product?.databaseId!, 1, undefined);
      menuCtx.openSidebar("cart");
    } catch (error) {
      notification.warning(error.message, true);
    } finally {
      setLoading(false);
    }
  };

  const stockQuantity = props.product?.stockQuantity
    ? props.product?.stockQuantity
    : 0;

  return (
    <div className={`Product${props.big ? " Product--big" : ""}`}>
      {props.onRemove && <RemoveItemButton onClick={props.onRemove} />}
      <div className="Product__image_and_slider">
        <div className="Product__image">
          <span className="Product__image__holder">
            <ThemeLink to={`/product/${props.product?.slug}`}>
              <Image
                src={img?.sourceUrl || img?.mediaItemUrl || thumbnail}
                alt={props.product?.name}
              />
            </ThemeLink>
          </span>
          {/* <ProductWatermark tag={watermark} /> */}
          <div className="Product__info">
            {discountedPercentage > 0 && (
              <span className="Product__discount">
                -{discountedPercentage.toFixed()}%
              </span>
            )}
            {stockStatus === 0 ? (
              <span className="Product__out-of-stock">
                {t(messages.outOfStock)}
              </span>
            ) : (
              stockQuantity <= 4 && (
                <span className="Product__out-of-stock Product__info-limited">
                  {t(messages.limitedProducts)}
                </span>
              )
            )}
          </div>
          {!props.noWishlistButton && props?.product?.databaseId && (
            <WishListButton id={props.product?.databaseId} />
          )}
        </div>
        <div className="Product__images-slider">
          {sliderImages?.map((img, index) => {
            return (
              <div
                key={`${img?.slug}-${index}`}
                onMouseOver={() => setSelectImg(img.slug)}
                className={cs(
                  "Product__images-slider-img",
                  selectImg === img.slug && "active"
                )}
              >
                <img src={img.sourceUrl} alt={props.product?.name} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="Product__content">
        <ThemeLink to={`/product/${props.product?.slug}`}>
          <h4 className="Product__content__title">
            {renderProductFieldsByLanguage(props.product!, "titleEn", "name")}
          </h4>
        </ThemeLink>
        {/* {props.product?.salePrice ? (
          <ProductPricesTemporary
            regular={props.product?.regularPrice ?? ""}
            sale={props.product?.salePrice ?? ""}
          />
        ) : ( */}
        {/* )} */}
        <ProductPrices
          regular={props.product?.regularPrice ?? ""}
          sale={props.product?.salePrice ?? ""}
          onSale={props.product?.onSale}
        />
        {props.gridView === "ONE" && (
          <div
            dangerouslySetInnerHTML={{
              __html: renderProductFieldsByLanguage(
                props.product!,
                "descriptionEn",
                "description"
              )?.toString(),
            }}
          />
        )}
        <button
          id="addToCardBtn"
          className="btn--addtocart"
          onClick={handleAddToCart}
          disabled={loading || stockStatus === 0}
        >
          {loading ? (
            <Spinner
              style={{
                color: "#fff",
                width: "1.25rem",
                height: "1.25rem",
                borderWidth: ".2em",
                margin: "auto",
              }}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <ShoppingCart
                style={{ marginRight: 8 }}
                fill="white"
                width={20}
                height={20}
              />{" "}
              {t(actions.addToBag)}
            </div>
          )}
        </button>{" "}
      </div>
    </div>
  );
};
