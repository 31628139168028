import React from "react";
import { Alert as ReactStrapAlert, Container } from "reactstrap";
import { ReactComponent as SuccessIcon } from "../../assets/icons/check.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/info.svg";
import { ReactComponent as DangerIcon } from "../../assets/icons/alert-circle.svg";

import "./Alert.scss";

export type AlertType = "success" | "warning" | "danger";

interface Props {
  type?: AlertType;
  message: string;
  className?: string;
  autoClose?: boolean;
  onClose?: () => void;
}

const getIcon = (type?: AlertType) => {
  switch (type) {
    case "success":
      return SuccessIcon;
    case "danger":
      return DangerIcon;
    case "warning":
      return WarningIcon;
    default:
      return null;
  }
};

export const Alert = (props: Props) => {
  const Icon = getIcon(props.type);

  return (
    <div className={`Alert ${props.className ? props.className : ""}`}>
      <ReactStrapAlert
        className={`Alert__${props.type || "default"}`}
        color={props.type}
      >
        <Container>
          {Icon && <Icon style={{ marginRight: 10 }} />}

          <p>{props.message}</p>

          {props.onClose && !props.autoClose && (
            <button className="close" onClick={props.onClose} />
          )}
        </Container>
      </ReactStrapAlert>
    </div>
  );
};
