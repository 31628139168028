import React from "react";
import cn from "classnames";
import { Button as RButton, ButtonProps, Spinner } from "reactstrap";
import { useRouter } from "../../../lib/hooks/useRouter";
import { IconDefinition } from "../Icon/Icon.generated";
import { Icon } from "../Icon/Icon";

import "./Button.scss";

interface Props extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
}

export const Button = (props: Props) => {
  const {
    children,
    onClick,
    className,
    rightIcon,
    leftIcon,
    loading,
    ...rest
  } = props;
  const router = useRouter();

  const handleOnClick = () => {
    if (props.to) {
      router.history.push(props.to);
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <RButton
      {...rest}
      disabled={loading || props.disabled}
      className={cn(
        "Button",
        props.color && `Button--${props.color}`,
        className
      )}
      onClick={handleOnClick}
    >
      {loading ? (
        <span>
          <Spinner spin={true} icon="loader" />
        </span>
      ) : (
        <>
          {leftIcon && <Icon icon={leftIcon} />}

          {children}

          {rightIcon && <Icon icon={rightIcon} />}
        </>
      )}
    </RButton>
  );
};
