import React from "react";

import { Slide } from "../../components/home/HomepageSlide/Slide";
import { Container } from "../../components/Container/Container";
import { HomeProducts } from "../../components/home/HomeProducts/HomeProducts";
import { BrandsIcons } from "../../components/shared/BrandsIcons/BrandsIcons";

import "./Home.scss";
import { SlideB } from "../../components/home/HomepageSlide/SlideB";

export const Home = () => {
  return (
    <div className="Home">
      <Slide />
      <SlideB />
      <Container>
        <HomeProducts />
        <BrandsIcons />
        {/* <NewIn />
        <Campaigns
          title=""
          campaign={
            slider.data?.generalOptions?.generalOptions?.homeAds
              ?.b1GridCampaigns
          }
        />
        <OnSale />
        <Video />
        <TopSale />
        <Campaigns
          title="Në trend"
          campaign={
            slider.data?.generalOptions?.generalOptions?.homeAds
              ?.b2GridCampaigns
          }
        />
        <ThreeCampaigns /> */}
      </Container>
    </div>
  );
};
