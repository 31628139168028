import React from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";

import { BrandsLoader } from "./BrandsLoader";
import { ThemeLink } from "../ThemeLink/ThemeLink";
import { ApolloErrorGuard } from "../ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../HandleLoadingState/HandleLoadingState";
import { Image } from "../Image/Image";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";

import * as brandsQueries from "../../../queries/brands";

import "./BrandsIcons.scss";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

export interface HomeVendorProps {
  name: string;
  slug: string;
  brandData: {
    logo: {
      sourceUrl: string;
    };
  };
}

export const BrandsIcons = () => {
  const { loading, data, error } = useQuery(brandsQueries.getHomeBrands);
  const history = useHistory();
  const items: HomeVendorProps[] =
    data?.generalOptions?.generalOptions?.randomBrands?.randomBrands ?? [];
  const lngCtx = useLanguageContext();
  const prefix = lngCtx.getLangPrefix();

  return (
    <div className="BrandsIcons">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<BrandsIconsLoader total={16} />}
      >
        <ApolloErrorGuard error={error}>
          <div className="BrandsIcons">
            <ul className="BrandsIcons__list">
              {items.map((item: HomeVendorProps) => {
                return (
                  item.brandData?.logo && (
                    <li
                      onClick={() =>
                        history.push(`${prefix}/brands/${item.slug}`)
                      }
                      className="BrandsIcons__list__item"
                      key={`${item.slug}-home-brands-listing`}
                    >
                      <ThemeLink to={`/brands/${item.slug}`}>
                        {item.brandData?.logo?.sourceUrl ? (
                          <Image
                            alt={item.name}
                            src={item.brandData.logo.sourceUrl}
                          />
                        ) : (
                          <span className="BrandsIcons__list__item__name">
                            {removeHTMLEntities(item?.name)}
                          </span>
                        )}
                      </ThemeLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};

export const BrandsIconsLoader = (props: { total: number }) => {
  const items = [];

  while (items.length <= props.total) {
    items.push(<BrandsLoader key={`${items.length}-home-brands-listing`} />);
  }

  return (
    <div className="BrandsIcons">
      <ul className="BrandsIcons__list">{items}</ul>
    </div>
  );
};
