import React from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import {
  GET_ORDERS_QUERY,
  Orders,
  OrdersItem,
} from "../../../queries/ordersQueries";
import { getFormatedDate } from "./../../../lib/helpers/getFormatedDate";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";
import { NavLink } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { LoadingPlaceholder } from "../../shared/LoadingPlaceholder/LoadingPlaceholder";
import { errors, fields } from "../../../lib/translation/strings";
import { Image } from "../../shared/Image/Image";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";

import "./ProfileOrders.scss";

export const ProfileOrderItem = (props: { order: OrdersItem }) => {
  const { t } = useTranslation();
  const { order } = props;

  return (
    <NavLink
      className="ProfileOrderItem"
      to={`/my-profile/orders/${order.databaseId}`}
    >
      <div className="ProfileOrderItem__thumb">
        <Image
          src={order?.lineItems?.nodes[0]?.product?.image?.sourceUrl}
          alt=""
        />
      </div>
      <div className="ProfileOrderItem__content">
        <div className="ProfileOrderItem__info">
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.order)}: </strong>
            {order.orderNumber}
          </span>
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.status)}: </strong>
            {t(order.status.toLowerCase())}
          </span>
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.orderDate)}: </strong>
            {getFormatedDate(order.date, true)}
          </span>
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.total)}: </strong>
            {removeHTMLEntities(order.total)}
          </span>
        </div>
      </div>
    </NavLink>
  );
};

export const ProfileOrders = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery<Orders>(GET_ORDERS_QUERY);

  const orderData = data?.customer?.orders?.nodes;

  const noOrders = !orderData || orderData.length === 0;

  return (
    <div className="ProfileOrders">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <Row>
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <LoadingPlaceholder />
            </Col>
          </Row>
        }
      >
        <ApolloErrorGuard error={error}>
          {noOrders ? (
            <p>{t(errors.noOrdersFound)}</p>
          ) : (
            <Row>
              {orderData?.map((order: any, i) => {
                return (
                  <Col xs={12} md={4} key={`${order.id}-${i}`}>
                    <ProfileOrderItem order={order} />
                  </Col>
                );
              })}
            </Row>
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
