import {
  TranslationsResults,
  TranslationItemKey,
  TranslationItem
} from "../../../queries/translationsQueries";
import { Translations } from "./LanguageContextProvider";

export const transformTranslationsResults = (
  translations: TranslationsResults
): Translations => {
  const data = Object.values(translations.stringTranslations.themeTranslations);

  const strings: Translations = {
    sq: {},
    en: {}
  };

  const allTranslations: TranslationItem[] = data.reduce((carry, array) => {
    if (!carry) carry = [];
    return carry.concat(array);
  });

  allTranslations
    .filter((k: TranslationItem) => !!k.key)
    .forEach((k: TranslationItem) => {
      Object.keys(strings).forEach((lang: string) => {
        strings[lang as keyof Translations][k.key] =
          k[lang as TranslationItemKey];
      });
    });
  return strings;
};
