import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ListOfProducts } from "../../ListOfProducts/ListOfProducts";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import * as shopQueries from "../../../queries/shop";
import { useOrderFieldsFromSlug } from "../../../lib/hooks/useOrderFieldsFromSlug";
import { useInitialSearchParams } from "../../../lib/hooks/useInitialSearchParams";

import "./HomeProducts.scss";

const POSTS_LIMIT = 15;

export const HomeProducts = () => {
  const orderFields = useOrderFieldsFromSlug();
  const initialSearchParams = useInitialSearchParams();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data: dataNewProducts } = useQuery(shopQueries.NEW_PRODUCTS);

  const date = dataNewProducts?.themeOptions?.themeOptions?.newProducts?.split(
    "/"
  );

  const { loading, data, error, fetchMore } = useQuery<
    shopQueries.GetProductsResponse,
    shopQueries.GetProductsVariables
  >(shopQueries.getProducts, {
    fetchPolicy: "cache-first",
    variables: {
      before: "",
      after:
        initialSearchParams && initialSearchParams.page
          ? (initialSearchParams.page as string)
          : "",
      limit: 100,
      category: "",
      field: orderFields.field,
      order: orderFields.order,
      day: date ? parseInt(date[0]) : undefined,
      month: date ? parseInt(date[1]) : undefined,
      year: date ? parseInt(date[2]) : undefined,
    },
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        limit: POSTS_LIMIT,
        after: data?.products?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        // if (pageInfo.endCursor) {
        //   shopHandlers.updateSingleQueryParameter(
        //     "page",
        //     previousResult?.products?.pageInfo?.endCursor,
        //     () => {}
        //   );
        // }

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              endCursor: pageInfo?.endCursor,
              hasNextPage: pageInfo?.hasNextPage,
            },
          },
        };
      },
    });
  };

  const handleLoadPrevious = () => {
    fetchMore({
      variables: {
        last: POSTS_LIMIT,
        first: undefined,
        limit: undefined,
        after: undefined,
        before: data?.products?.pageInfo?.startCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...newNodes, ...previousResult.products.nodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              startCursor: pageInfo?.startCursor,
              hasPreviousPage: pageInfo?.hasPreviousPage,
            },
          },
        };
      },
    });
  };

  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const hasPreviousPage = !!data?.products?.pageInfo?.hasPreviousPage;
  const products = data?.products?.nodes ?? [];
  // const noProductsAvailable = !products || Number(products.length) === 0;

  // const firstProducts = products?.slice(0, 4) ?? [];
  // const secondProducts = products?.slice(5) ?? [];

  return (
    <div className="HomeProducts animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <ListOfProducts
          big
          className="HomeProducts__products"
          loadingPlaceholderNumber={POSTS_LIMIT}
          loading={loading}
          error={error?.message}
          products={products}
          isLoading={isLoadingMore}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageInfo={hasNextPage}
          onLoadPrevious={handleLoadPrevious}
          onLoadMore={handleLoadMore}
        />
        {/* <ThreeCampaigns />
        <ListOfProducts
          big
          className="HomeProducts__products"
          loadingPlaceholderNumber={POSTS_LIMIT}
          loading={loading}
          error={error?.message}
          products={secondProducts}
          isLoading={isLoadingMore}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageInfo={hasNextPage}
          onLoadPrevious={handleLoadPrevious}
          onLoadMore={handleLoadMore}
        /> */}
      </ApolloErrorGuard>
    </div>
  );
};
