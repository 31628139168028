import React from "react";
import { ContentPageLoader } from "./ContentPageLoader";

export const ContentPagePlaceholder = () => {
  return (
    <div className="ContentPagePlaceholder mt-5 d-flex align-items-center justify-content-center">
      <ContentPageLoader />
    </div>
  );
};
