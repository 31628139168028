import React from "react";
import cs from "classnames";

import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";

import "./ProductPrices.scss";

interface Props {
  sale?: string | null;
  regular?: string;
  onSale?: boolean;
}

export const ProductPrices = (props: Props) => {
  const classes = cs("ProductPrices", {
    sale: props.onSale,
  });

  return (
    <div className={classes}>
      <h4 className="regular">
        {props.regular && removeHTMLEntities(props.regular)}
      </h4>
      {props.onSale && (
        <h4 className="sale">{removeHTMLEntities(props.sale!)}</h4>
      )}
    </div>
  );
};
