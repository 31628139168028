// import { ProductSingleProps } from "../../../../queries/archive";

export const getProductImages = (product: any) => {
  const thumbnail = product?.image;

  const gallery = product?.galleryImages?.nodes || [];

  if (gallery?.length === 0 && !thumbnail) {
    return [];
  }

  return [
    thumbnail,
    ...gallery?.filter((x: any) => x?.slug !== thumbnail?.slug),
  ];
};
