import { fromPromise } from "apollo-link";
import { onError } from "apollo-link-error";

import {
  deleteLocalValue,
  setLocalValue
} from "../lib/helpers/localStorageHelpers";
import { getNewToken } from "./apolloHelpers";

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!graphQLErrors) {
    return;
  }
  let hasExpiredTokenError = false;
  for (let err of graphQLErrors) {
    let errM = err as any;
    if (errM.debugMessage && errM.debugMessage.indexOf("invalid-jwt") >= 0 && !hasExpiredTokenError) {
      hasExpiredTokenError = true;
      return fromPromise(
        getNewToken().catch((e) => {
          deleteLocalValue("authToken");
          deleteLocalValue("refreshToken");
          window.location.reload();
          return;
        })
      ).flatMap((accessToken): any => {
        if (accessToken && accessToken.length > 0) {
          setLocalValue("authToken", accessToken);
          // window.location.reload();
        }
        return forward(operation);
      });
    }
  }
});
