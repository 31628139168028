import { useMutation } from "@apollo/react-hooks";
import React from "react";
import Rate from "rc-rate";
import {
  actions,
  errors,
  fields,
  formInputs,
  formMessages,
  formValidation,
} from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Container,
  Row,
  FormFeedback,
  Input,
  Form,
  Label,
} from "reactstrap";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { REVIEWS } from "../../queries/mutations/generalMutation";

import "rc-rate/assets/index.css";
import "./ProductReviewModal.scss";
import { Button } from "../shared/Button/Button";
import { useProductReviewFormik } from "../../lib/formik/useProductReviewFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

interface Props {
  open: boolean;
  toggle: () => void;
}

export const ProductReviewModal = (props: Props) => {
  const { open, toggle } = props;
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const ntfCtx = useNotification();
  const { product } = useProductSingleContext();
  // const hiddenFileInput = React.useRef(null);
  // const [file, setFile] = React.useState<any>();
  const [mutation, { loading }] = useMutation(REVIEWS);

  const formik = useProductReviewFormik({
    onSubmit: async (values) => {
      try {
        const res = await mutation({
          variables: {
            rating: values.rating,
            author: authCtx.user?.username,
            content: values.content,
            commentOn: product.databaseId,
          },
        });

        if (!res) {
          return;
        }

        props.toggle();
        ntfCtx.success(t(formMessages.thanksForComment), true);
      } catch (error) {
        ntfCtx.danger(t(errors.wentWrong), true);
      }
    },
  });

  // const feedbackOptions =
  //   data?.generalOptions?.generalOptions?.feedbackForm?.feedbackOptions ?? [];

  return (
    <Modal style={{ maxWidth: "800px" }} isOpen={open} toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>
        <span>{t(actions.addComment)}</span>
      </ModalHeader>
      <ModalBody>
        <Container>
          <div className="ProductReviewModal">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs="12" sm="12">
                  <div className="ProductReviewModal__rate">
                    <Label>{t(fields.yourRating)}?</Label>
                    <Rate
                      style={{ fontSize: 30 }}
                      allowHalf={false}
                      onChange={(value: number) =>
                        formik.setFieldValue("rating", value)
                      }
                    />
                    {formik.values.rating === 0 && formik.touched.rating && (
                      <span className="ProductReviewModal__rate_error">
                        {t(formValidation.required)}
                      </span>
                    )}
                  </div>
                </Col>
                {/* <Col xs="12" sm="12">
                  <FormGroup>
                    <Label
                      className="form-control  ProductReviewModal__input"
                      for="photo"
                    >
                      {file ? file.title?.raw : "Shtoni një foto..."}
                    </Label>
                    <Input
                      ref={hiddenFileInput}
                      type="file"
                      id="photo"
                      placeholder="photo"
                      onChange={storeFile}
                      style={{ display: "none" }}
                      className="form-control"
                      value={undefined}
                      invalid={!!formik.errors.photo && formik.touched.photo}
                      accept="image/*"
                    />
                    <FormFeedback>{formik.errors.photo}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" sm="12">
                  <FormGroup className="form-group">
                    <Input
                      type="textarea"
                      id="advantages"
                      placeholder="Avantazhet"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.advantages}
                      invalid={
                        !!formik.errors.advantages && formik.touched.advantages
                      }
                    />
                    <FormFeedback>{formik.errors.advantages}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" sm="12">
                  <FormGroup className="form-group">
                    <Input
                      type="textarea"
                      id="disadvantages"
                      placeholder="Disavantazhet"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.disadvantages}
                      invalid={
                        !!formik.errors.disadvantages &&
                        formik.touched.disadvantages
                      }
                    />
                    <FormFeedback>{formik.errors.disadvantages}</FormFeedback>
                  </FormGroup>
                </Col> */}
                <Col xs="12" sm="12">
                  <FormGroup className="form-group">
                    <Input
                      type="textarea"
                      id="content"
                      placeholder={t(formInputs.yourComment)}
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.content}
                      invalid={
                        !!formik.errors.content && formik.touched.content
                      }
                    />
                    <FormFeedback>{formik.errors.content}</FormFeedback>
                  </FormGroup>
                </Col>
                {/* <Col xs="12" sm="12">
                  <FormGroup>
                    <Input
                      type="text"
                      id="user"
                      placeholder={t(formInputs.name)}
                      onChange={formik.handleChange}
                      className="form-control-custom"
                      value={formik.values.user}
                      invalid={!!formik.errors.user && formik.touched.user}
                      style={{ width: "100%" }}
                    />
                    <FormFeedback>{formik.errors.user}</FormFeedback>
                  </FormGroup>
                </Col> */}
                <div className="w-100" />
                <Col xs="12">
                  <FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={loading || formik.values.rating === 0}
                      className="w-100-mobile"
                    >
                      {loading ? <Spinner size="sm" /> : t(actions.send)}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};
