import { FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { BlockButton } from "../../components/shared/BlockButton/BlockButton";
import { actions, formInputs } from "../../lib/translation/strings";

interface Props {
  loading: boolean;
  formik: FormikValues;
}

export const ContactForm = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div className="ContactForm">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" sm="6">
            <FormGroup className="form-group">
              <Label for="nameSurname">
                {t(formInputs.name)} {t(formInputs.lastName)}
              </Label>
              <Input
                id="name"
                type="text"
                name="nameSurname"
                value={formik.values.nameSurname}
                onChange={formik.handleChange}
                className="form-control"
                invalid={
                  !!formik.errors.nameSurname && formik.touched.nameSurname
                }
              />
              <FormFeedback>{formik.errors.nameSurname}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="email">{t(formInputs.email)}</Label>
              <Input
                id="email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="form-control-custom"
                invalid={!!formik.errors.email && formik.touched.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label for="message">{t(formInputs.message)}</Label>
              <Input
                id="message"
                type="textarea"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                className="form-control-custom"
                invalid={!!formik.errors.message && formik.touched.message}
              />
              <FormFeedback>{formik.errors.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <BlockButton
                type="submit"
                disabled={props.loading}
                className=" Contact__btn_submit w-100-mobile"
              >
                {props.loading ? t(actions.saving) : t(actions.send)}
              </BlockButton>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
