import React from "react";
import ContentLoader from "react-content-loader";

import "./SlideLoader.scss";

export const SlideLoader = () => {
  return (
    <div className="SliderLoader">
      <ContentLoader
        speed={2}
        width="100%"
        height="calc(100vh - 123px)"
        backgroundColor="#f3f3f3"
        foregroundColor="#e7e3e3"
        className="SlideLoader__full"
      >
        <rect x="0" y="0" width="100%" height="calc(100vh - 123px)" />
      </ContentLoader>
    </div>
  );
};
