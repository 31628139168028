import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input, FormFeedback, Row, Col } from "reactstrap";

import { fields, messages } from "../../../lib/translation/strings";

import { CartTotal } from "../../../pages/Cart/CartTotal";
import { ThemeLink } from "../../shared/ThemeLink/ThemeLink";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";

import "./CheckoutFooter..scss";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

interface Props {
  formik: CheckoutFormik;
}

export const CheckoutFooter = (props: Props) => {
  const cartCtx = useCartContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="OrderDetails__total">
        {/*<div className="OrderDetails__total__price">*/}
        {/*  <strong>{t(fields.subTotal)}</strong>*/}
        {/*  <span>{removeHTMLEntities(cartCtx.subtotal)}</span>*/}
        {/*</div>*/}
        <div className="OrderDetails__total__price">
          <strong>{t(fields.total)}</strong>
          <strong>{removeHTMLEntities(cartCtx.total)}</strong>
        </div>
      </div>
      <Row>
        <Col md="6">
          <FormGroup check className="agreement mt-4 CheckoutFooter">
            <Label
              for="termsAndConditions"
              check={props.formik.values.termsAndConditions}
            >
              <Input
                type="checkbox"
                name="termsAndConditions"
                id="termsAndConditions"
                onChange={props.formik.handleChange}
                invalid={
                  !!props.formik.errors.termsAndConditions &&
                  props.formik.touched.termsAndConditions
                }
              />
              {t(messages.agreeWith)}{" "}
              <ThemeLink to="/page/36869">
                {t(messages.purchaseTerms)}
              </ThemeLink>
              <FormFeedback>
                {props.formik.errors.termsAndConditions}
              </FormFeedback>
            </Label>
          </FormGroup>
        </Col>
        <Col md="6">
          <CartTotal formik={props.formik} />
        </Col>
      </Row>
    </>
  );
};
