import React from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../lib/hooks/useRouter";
import { GET_PAGE_BY_ID, Page } from "../../../queries/generalQueries";

import { ApolloErrorGuard } from "../../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../components/shared/HandleLoadingState/HandleLoadingState";

import { ContentPagePlaceholder } from "./ContentPagePlaceholder";
import { DefaultPage } from "../../../pages/DefaultPage/DefaultPage";

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

export const ContentPage = (props: Props) => {
  const router: any = useRouter();
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_PAGE_BY_ID, {
    variables: {
      id: parseInt(props?.match?.params?.id),
    },
  });

  const language = i18n.language;
  const content: Page = data?.pages.nodes[0];

  if (!loading && !content) {
    router.push("/404");
  }

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<ContentPagePlaceholder />}
    >
      <ApolloErrorGuard error={error}>
        <DefaultPage
          title={t(
            language === "sq" ? content?.title : content?.pageEnglish?.titleEn
          )}
          content={t(
            language === "sq"
              ? content?.content
              : content?.pageEnglish?.descriptionEn
          )}
        />
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
