import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

export const ThemeLink = (
  props: NavLinkProps & React.RefAttributes<HTMLAnchorElement>
) => {
  const langCtx = useLanguageContext();
  const prefix = langCtx.getLangPrefix();

  return (
    <NavLink {...props} to={prefix + props.to}>
      {props.children}
    </NavLink>
  );
};
