import { ProductSingleProps } from "../../../queries/archive";

export const getProductImages = (product: ProductSingleProps) => {
  const thumbnail = product.image;
  const gallery = product.galleryImages.nodes || [];

  if (gallery.length === 0 && !thumbnail) {
    return [];
  }

  return [thumbnail, ...gallery.filter((x) => x.slug !== thumbnail.slug)];
};
