import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Input } from "reactstrap";

import { fields, formInputs } from "../../../lib/translation/strings";

import { SelectCountry } from "../../shared/SelectCountry/SelectCountry";
import { HeadingTitle } from "../../shared/HeadingTitle/HeadingTitle";
import { ProfileAddressFormik } from "../../../lib/formik/profile/useProfileAddressFormik";

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressShipping = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <>
      <Col xs="12" sm="12">
        <HeadingTitle title={t(fields.shippingAddress)} />
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.name)}</Label>
          <Input
            type="text"
            name="shipping.firstName"
            placeholder={t(formInputs.name)}
            onChange={formik.handleChange}
            value={formik.values.shipping.firstName || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.lastName)}</Label>
          <Input
            type="text"
            name="shipping.lastName"
            onChange={formik.handleChange}
            placeholder={t(formInputs.lastName)}
            value={formik.values.shipping.lastName || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="country">{t(fields.country)}</Label>
          <SelectCountry
            name="country"
            hasError={false}
            errorMessage={""}
            onSelect={(country: string) => {
              formik.setFieldValue("shipping.country", country);
            }}
            initialValue={formik.values.shipping.country}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="city">{t(fields.city)}</Label>
          <Input
            type="text"
            name="shipping.city"
            onChange={formik.handleChange}
            placeholder={t(fields.city)}
            value={formik.values.shipping.city || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="address">{t(formInputs.address)}</Label>
          <Input
            type="text"
            name="shipping.address1"
            onChange={formik.handleChange}
            placeholder={t(formInputs.address)}
            value={formik.values.shipping.address1 || ""}
          />
        </FormGroup>
      </Col>
    </>
  );
};
