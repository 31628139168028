// import { ProductSingleVariationProps } from "../../../../queries/archive";

// TODO: Accept variations array as a prop;
// TODO: Remove types that are as any;
export const getProductAttributes: any = (variations: any) => {
  const values: any = {};

  variations.forEach((item: any) => {
    const attributes = item.attributes;

    attributes.forEach((attr: any) => {
      const keyExists = attr.attribute in values;

      if (!keyExists) {
        values[attr.attribute] = {
          name: attr.attribute,
          values: new Set([])
        };
      }

      values[attr.attribute] = {
        ...values[attr.attribute],
        values: values[attr.attribute].values.add(attr.value)
      };
    });
  });

  return Object.values(values);
};
