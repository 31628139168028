import React, { useRef } from "react";
import cs from "classnames";

import { useSidebarContext } from "../../lib/context/SidebarContext/SidebarContext";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";

import "./Sidebar.scss";
import { Icon } from "../shared/Icon/Icon";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Sidebar = (props: Props) => {
  const ref = useRef(null);
  const sidebarCtx = useSidebarContext();

  useOutsideClickDetection(ref, () => sidebarCtx.closeSidebar());

  return (
    <div
      ref={ref}
      className={cs(
        "Sidebar",
        sidebarCtx.isOpened && "Sidebar--opened",
        props.className
      )}
    >
      <span
        className="Sidebar__close"
        role="button"
        onClick={sidebarCtx.closeSidebar}
      >
        <Icon icon="x" />
      </span>
      <div className="Sidebar__content">{props.children}</div>
    </div>
  );
};
