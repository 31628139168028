import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Input, FormFeedback, Row } from "reactstrap";

import { actions, formInputs } from "../../../lib/translation/strings";

import { ProfilePasswordFormik } from "../../../lib/formik/profile/useProfilePasswordFormik";

import { BlockButton } from "../../shared/BlockButton/BlockButton";

interface Props {
  loading: boolean;
  formik: ProfilePasswordFormik;
}

export const ProfilePasswordForm = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="profilePassword">{t(formInputs.newPassword)}</Label>
          <Input
            type="password"
            name="profilePassword"
            onChange={formik.handleChange}
            value={formik.values.profilePassword}
            invalid={
              !!formik.errors.profilePassword && formik.touched.profilePassword
            }
          />
          <FormFeedback>{formik.errors.profilePassword}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="confirmProfilePassword">
            {t(formInputs.confirmPassword)}
          </Label>
          <Input
            type="password"
            name="confirmProfilePassword"
            onChange={formik.handleChange}
            value={formik.values.confirmProfilePassword}
            invalid={
              !!formik.errors.confirmProfilePassword &&
              formik.touched.confirmProfilePassword
            }
          />
          <FormFeedback>{formik.errors.confirmProfilePassword}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup>
          <BlockButton
            type="submit"
            className="w-100-mobile"
            disabled={!formik.dirty || props.loading}
            onClick={formik.submitForm}
          >
            {props.loading ? t(actions.saving) : t(actions.save)}
          </BlockButton>
        </FormGroup>
      </Col>
    </Row>
  );
};
