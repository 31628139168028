import React, { useEffect, useState } from "react";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

import { ProductPrices } from "../shared/ProductPrices/ProductPrices";

export const SingleProductContent = () => {
  const { product, variation } = useProductSingleContext();
  const { renderProductFieldsByLanguage } = useProductSingleHelpers();
  const [price, setPrice] = useState(() => {
    return {
      regular: product.regularPrice,
      sale: product.salePrice,
    };
  });

  useEffect(() => {
    setPrice({
      regular: variation?.regularPrice || product.regularPrice,
      sale: variation?.salePrice || product.salePrice,
    });
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="SingleProductContent__header">
        {/* <div className="top-content">
          <WishListButton id={product.databaseId} />
        </div> */}
        <h1 className="SingleProduct__content__title">
          {renderProductFieldsByLanguage(product, "titleEn", "name")}
        </h1>
        {/* {price.sale ? (
          <ProductPricesTemporary regular={price.regular} sale={price.sale} />
        ) : ( */}
        <ProductPrices
          onSale={product.onSale}
          regular={price.regular}
          sale={price.sale}
        />
        {/* )} */}
      </div>

      {product?.description && (
        <div className="SingleProduct__content__description">
          <div
            dangerouslySetInnerHTML={{
              __html: renderProductFieldsByLanguage(
                product,
                "descriptionEn",
                "description"
              ).toString(),
            }}
          />
        </div>
      )}
    </>
  );
};
