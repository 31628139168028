import React from "react";
import { Switch, Route } from "react-router-dom";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

import { ProfileOrders } from "../../components/ProfilePages/ProfileOrders/ProfileOrders";
import { ProfileAddress } from "../../components/ProfilePages/ProfileAddress/ProfileAddress";
import { ProfileAccount } from "../../components/ProfilePages/ProfileAccount/ProfileAccount";
import { ProfilePassword } from "../../components/ProfilePages/ProfilePassword/ProfilePassword";
import { ProfileOrdersSingle } from "../../components/ProfilePages/ProfileOrders/ProfileOrdersSingle";

export const ProfileContent = () => {
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  return (
    <div className="ProfileContent flex-fill">
      <Switch>
        <Route
          exact
          path={`${langPrefix}/my-profile/orders`}
          component={ProfileOrders}
        />

        <Route
          path={`${langPrefix}/my-profile/privacy`}
          component={ProfilePassword}
        />

        <Route
          path={`${langPrefix}/my-profile/orders/:slug`}
          component={ProfileOrdersSingle}
        />

        <Route
          path={`${langPrefix}/my-profile/address`}
          component={ProfileAddress}
        />

        <Route component={ProfileAccount} />
      </Switch>
    </div>
  );
};
