import React, { useState } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import {
  Specification,
  useProductSingleContext,
} from "../../lib/context/ProductSingleContext/ProductSingleContext";
import "./ProductSimpleAttributes.scss";
import { Reviews } from "../Reviews/Reviews";
import { actions, fields } from "../../lib/translation/strings";

export const ProductSimpleAttributes = () => {
  const { t, i18n } = useTranslation();
  const {
    product,
    productDetails,
    productSpecifications,
    productSpecificationsEn,
  } = useProductSingleContext();

  const [tab, setTab] = useState<"VECORITE" | "REVIEWS">("VECORITE");

  const productDetailsArray = Object.entries(productDetails).map(
    ([label, value]) => {
      return {
        label,
        value,
      };
    }
  );

  const filteredProductDetailsArray = productDetailsArray.filter(
    (item) => (item.value !== null || undefined) && item.label !== "__typename"
  );

  const productSpecifiations =
    productSpecifications &&
    productSpecifications?.specifications &&
    productSpecifications?.specifications?.filter((item) => !!item.title);

  const productSpecifiationsEn =
    productSpecificationsEn &&
    productSpecificationsEn?.filter((item) => !!item.title);

  const finalSpecifications =
    i18n.language === "en" ? productSpecifiationsEn : productSpecifiations;

  return (
    <div className="ProductSimpleAttributes">
      <div className="ProductSimpleAttributes__features">
        <span
          style={
            tab === "VECORITE"
              ? { fontWeight: "bold", borderBottom: "1px solid #333" }
              : { fontWeight: "normal" }
          }
          onClick={() => setTab("VECORITE")}
        >
          {t(actions.features)}
        </span>
        <span
          style={
            tab === "REVIEWS"
              ? { fontWeight: "bold", borderBottom: "1px solid #333" }
              : { fontWeight: "normal" }
          }
          onClick={() => setTab("REVIEWS")}
        >
          {t(actions.reviews)}
        </span>
      </div>
      {tab === "VECORITE" ? (
        <>
          <div className="mt-2">
            {product?.globalAttributes?.nodes?.map((attr: any) => {
              return (
                <div
                  key={`${attr.name}-single-variations`}
                  className={cs(
                    "SingleProduct__content__variations",
                    "justify-content-between"
                  )}
                >
                  <div className="d-flex align-items-center">
                    <span className="label ProductSimpleAttributes__features__attr-name">
                      {t(attr.slug)}
                    </span>
                    {attr?.terms?.nodes?.map((i: { name: string }) => (
                      <span key={i.name}>{i.name}</span>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          {finalSpecifications?.map((item: Specification, index: number) => {
            return (
              <div key={index}>
                <span className="ProductSimpleAttributes__features mt-4">
                  {item?.title}
                </span>
                <div className="mt-2">
                  {item.specifics?.map(
                    (item: { title?: string; value?: string }) => {
                      return (
                        <div
                          key={`${item?.title}-single-variations`}
                          className={cs(
                            "SingleProduct__content__variations",
                            "justify-content-between"
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <span className="label ProductSimpleAttributes__features__attr-name">
                              {item?.title}
                            </span>
                            <span>{item?.value}</span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
          {filteredProductDetailsArray?.length > 0 && (
            <>
              <span className="ProductSimpleAttributes__features mt-4">
                {t(fields.others)}
              </span>
              <div className="mt-2">
                {filteredProductDetailsArray?.map(
                  (item: { label: string; value: string }) => {
                    return (
                      <div
                        key={`${item.label}-single-variations`}
                        className={cs(
                          "SingleProduct__content__variations",
                          "justify-content-between"
                        )}
                      >
                        <div className="d-flex align-items-center">
                          <span className="label ProductSimpleAttributes__features__attr-name">
                            {t(item.label)}
                          </span>
                          <span>{item.value}</span>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <Reviews />
      )}
    </div>
  );
};
