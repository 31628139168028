import React from "react";
import { Range } from "rc-slider";
import { useTranslation } from "react-i18next";
import { fields } from "../../lib/translation/strings";

import "./PriceRange.scss";
import "rc-slider/assets/index.css";

interface PriceRangeProps {
  state: {
    min: number;
    max: number;
  };
  handleChange: (value: number[]) => void;
  handleAfterOnChange: (value: number[]) => void;
}

const PriceRange = (props: PriceRangeProps) => {
  const { state, handleChange, handleAfterOnChange } = props;
  const { t } = useTranslation();
  const hanldeStyle = {
    borderColor: "#fe0001",
    boxShadow: "none",
    backgroundColor: "white",
  };

  return (
    <div className="PriceRange">
      <Range
        handleStyle={[hanldeStyle, hanldeStyle]}
        railStyle={{ backgroundColor: "#deded" }}
        trackStyle={[{ backgroundColor: "#fe0001" }]}
        allowCross={false}
        min={0}
        max={1500}
        defaultValue={[state.min, state.max]}
        onChange={handleChange}
        onAfterChange={handleAfterOnChange}
      />
      <div className="PriceRange__min-max">
        <span>{t(fields.price)}:</span>
        <div className="PriceRange__min-max-wrapper">
          <span>€ {state.min} - </span>
          <span>€ {state.max}</span>
        </div>
      </div>
    </div>
  );
};

export default PriceRange;
