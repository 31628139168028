import React from "react";
import ContentLoader from "react-content-loader";

export const BrandsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={131}
      height={133}
      viewBox="0 0 131 133"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="73" width="131" height="60" />
    </ContentLoader>
  );
};
