import React from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

import { useAuthContext } from "../lib/context/AuthContext/AuthContext";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";

import { removeLangFromUrl } from "../lib/helpers/removeLangFromUrl";
import { ThemeRedirect } from "../components/shared/ThemeRedirect/ThemeRedirect";

export const PrivateRoute = (props: RouteProps) => {
  const location = useLocation();
  const authCtx = useAuthContext();
  const langCtx = useLanguageContext();

  if (!authCtx.isAuthenticated) {
    const url = removeLangFromUrl(location.pathname);

    return (
      <ThemeRedirect
        to={`${langCtx.getLangPrefix()}/login?redirect=${encodeURIComponent(
          url
        )}`}
      />
    );
  }

  return <Route {...props} />;
};
