import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { removeHTMLTags } from "../../lib/helpers/removeHTMLTags";
import { messages } from "../../lib/translation/strings";
import { GET_PRODUCT_RETURN_CONDITIONS } from "../../queries/generalQueries";
import { Icon } from "../shared/Icon/Icon";
// import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

// import { ReactComponent as MetroTruck } from "../../../assets/icons/metro-truck.svg";
// import { ReactComponent as FeatherInfo } from "../../../assets/icons/feather-info.svg";

import "./SingleProductDetails.scss";

export const SingleProductDetails = () => {
  const [terms, setTerms] = useState(false);
  const { t, i18n } = useTranslation();

  const { data } = useQuery(GET_PRODUCT_RETURN_CONDITIONS);

  // const {
  //   product: { sku }
  // } = useProductSingleContext();
  // const { getProductDetails } = useProductSingleHelpers();
  // const details = getProductDetails(productOptions);
  //
  // const getDetailsContent = (item: string[]) => {
  //   return item[1];
  // };

  const productReturnConditions = data?.generalOptions?.generalOptions ?? {};

  return (
    <div className="SingleProductDetails">
      <div className="SingleProductDetails__terms">
        <div
          onClick={() => setTerms(!terms)}
          data-opened={terms}
          className="SingleProductDetails__terms--title"
        >
          <h2>{t(messages.returnProduct)}</h2>
          <Icon icon="arrow-right" />
        </div>
        <CSSTransition
          in={terms}
          timeout={200}
          classNames="animation"
          unmountOnExit
        >
          <div className="SingleProductDetails__terms_content">
            <p>
              {i18n?.language === "en"
                ? removeHTMLTags(productReturnConditions?.returnConditionsEn)
                : removeHTMLTags(productReturnConditions?.returnConditions)}
            </p>
          </div>
        </CSSTransition>
      </div>
      {/* <ul className="SingleProductDetails__list">
        {sku && (
          <li>
            <p>
              <span style={{ width: 100 }} className="mr-5 d-inline-block">
                {t(fields.barcode)}:
              </span>
              {sku}
            </p>
          </li>
        )}

        {details.map((item, index) => {
          const content = getDetailsContent(item);

          return (
            <li key={`${item[0]}-product-details`}>
              <p>
                <span>{t(`fields${_.startCase(item[0])}`)}:</span>
                {content}
                {index !== details.length - 1 && ","}
              </p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};
