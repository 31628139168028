const regex = /GraphQL error:/;

/**
 * String errors that comes from catch block. _error.message_
 * @param error
 */
export const formatGraphqlErrors = (error?: string) => {
  if (!error) {
    return "";
  }

  return error.replace(regex, "");
};
