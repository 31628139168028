import React, { useState } from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";

import "./Select.scss";

export interface SortSelectOption {
  value: string | number;
  label: string;
  isPlaceholder?: boolean;
}

interface Props {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: SortSelectOption[];

  value?: string;

  name: string;
  className?: string;
  loading?: boolean;
}

export const Select = (props: Props) => {
  const [value, setValue] = useState(props.value || "");
  const placeholderOption = props.options.find(o => o.isPlaceholder);

  const options = props.options
    ? props.options.filter(o => !o.isPlaceholder)
    : [];

  return (
    <div className={cs("Select", props.className)}>
      {props.loading && (
        <div className="Select__loading">
          <Spinner size="sm" color="secondary" className="Spinner" />
        </div>
      )}
      <select
        value={props.value || value}
        name={props.name}
        onChange={e => {
          setValue(e.target.value);
          props.onChange(e);
        }}
      >
        {placeholderOption && (
          <option value="">{placeholderOption.label}</option>
        )}
        {options.map((o, i) => (
          <option key={`${o.value}-${i}`} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};
