import React from "react";
import { useMenuContext } from "../../../lib/context/MenuContext/MenuContext";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";

import { Icon } from "../../../components/shared/Icon/Icon";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";

import "./Logo.scss";

interface Props {
  noLink?: boolean;
  loading?: boolean;
}

export const Logo = (props: Props) => {
  const menuCtx = useMenuContext();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  return (
    <div className="Logo">
      {!props.loading && (
        <span
          role="button"
          className="Logo__mobile-menu"
          onClick={menuCtx.openSidebar}
        >
          <Icon icon="menu" />
        </span>
      )}
      {props.noLink ? (
        <img
          style={
            isMobile ? { width: 140, height: 50 } : { width: 256, height: 79 }
          }
          src={require("../../../assets/images/led-com.png")}
          alt="logo"
        />
      ) : (
        <ThemeLink to="/">
          <img
            style={
              isMobile ? { width: 140, height: 50 } : { width: 256, height: 79 }
            }
            src={require("../../../assets/images/led-com.png")}
            alt="logo"
          />
        </ThemeLink>
      )}
    </div>
  );
};
