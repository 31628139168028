import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { removeHTMLTags } from "../../lib/helpers/removeHTMLTags";
import { actions, errors, messages } from "../../lib/translation/strings";
import { ProductReviewModal } from "../ProductReview/ProductReviewModal";
import { Button } from "../shared/Button/Button";

import "./Reviews.scss";

export const Reviews = () => {
  const { product } = useProductSingleContext();
  const [open, setOpen] = React.useState(false);
  const authCtx = useAuthContext();
  const { t } = useTranslation();
  const ntfCtx = useNotification();

  const handleClick = () => {
    if (!authCtx.isAuthenticated) {
      ntfCtx.warning(t(errors.noLogin));
      return;
    }

    setOpen(true);
  };

  const reviews = product?.reviews?.edges ?? [];

  return (
    <div className="Reviews">
      <div className="Reviews__left">
        {reviews?.length > 0 ? (
          <div className="Reviews__left__comments">
            {reviews?.map((item: any) => {
              return (
                <div
                  className="Reviews__left__comments--comment"
                  key={item.node?.id}
                >
                  <div className="Reviews__left__comments--comment-left">
                    <label>{item?.node?.author?.node?.name}</label>
                    <p>{removeHTMLTags(item?.node?.content)}</p>
                  </div>
                  <div className="Reviews__left__comments--comment-right">
                    <label style={{ color: "#f4a522" }}>{item?.rating}/5</label>
                    <label>{moment(item?.node?.date)?.format("L")}</label>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="Reviews__empty">
            <p>{t(messages.noComment)}</p>
          </div>
        )}
      </div>
      <div className="Reviews__right">
        <Button
          onClick={handleClick}
          color="primary"
          type="submit"
          className="w-100-mobile"
        >
          {t(actions.addComment)}
        </Button>
      </div>
      <ProductReviewModal open={open} toggle={() => setOpen(false)} />
    </div>
  );
};
