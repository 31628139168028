import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../lib/hooks/useRouter";
import { ReactComponent as LanguageIcon } from "../../assets/icons/language.svg";

import "./LanguageSwitcher.scss";

export type LanguageType = "sq" | "en";

const removeLangFromURL = (url: string) => {
  return url.replace("/en", "");
};

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { i18n } = useTranslation();

  // const classes = cs("LanguageSwitcher", {
  //   "LanguageSwitcher--light": props.light
  // });

  const switchLanguage = () => {
    const currentLanguage = i18n.language,
      newPrefix = currentLanguage === "sq" ? "/en" : "",
      newPath =
        removeLangFromURL(router.location.pathname) + router.location.search;

    i18n.changeLanguage(currentLanguage === "sq" ? "en" : "sq");
    router.push(newPrefix + newPath);
  };

  return (
    <div className="LanguageSwitcher">
      <button onClick={() => switchLanguage()}>
        <span className="LanguageSwitcher__icon">
          <LanguageIcon />
        </span>
      </button>
    </div>
  );
};
