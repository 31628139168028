import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formValidation } from "../translation/strings";

export interface ProductReviewInputs {
  rating: number;
  content: string;
}

interface ProductReviewFormOptions {
  initialValues?: ProductReviewInputs;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProductReviewInputs>
  ) => Promise<any>;
}

export const useProductReviewFormik = (options: ProductReviewFormOptions) => {
  const { t } = useTranslation();

  const ProductReviewSchema = Yup.object().shape({
    rating: Yup.string().required(t(formValidation.message)),
    content: Yup.string().required(t(formValidation.message)),
  });

  return useFormik({
    initialValues: {
      rating: 0,
      content: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProductReviewSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ProductReviewFormik = ReturnType<typeof useProductReviewFormik>;
