import React from "react";
import { useLoginFormik } from "../../lib/formik/useLoginFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { LoginForm } from "./LoginForm";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";
import { useMutation } from "react-apollo";
import { LOGIN_MUTATION } from "../../queries/mutations/userMutations";
import { getLocalValues } from "../../lib/helpers/localStorageHelpers";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { actions, errors } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

import "./Login.scss";

export interface LoginFields {
  email: string;
  password: string;
}

export const Login = () => {
  const authCtx = useAuthContext();
  const notificationCtx = useNotification();
  const { t } = useTranslation();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const localValues = getLocalValues();

  const formik = useLoginFormik({
    onSubmit: async (values) => {
      try {
        const res = await loginMutation({
          variables: {
            username: values.email,
            password: values.password,
            wishlist: localValues.wishlist,
          },
        });
        authCtx.login(res);
      } catch (e) {
        notificationCtx.danger(t(errors.wrongEmailOrPassword), false);
      }
    },
  });

  return (
    <div className="Login">
      <ThemeContainer>
        <div className="Login__wrapper">
          <h4>{t(actions.login)}</h4>
          <LoginForm formik={formik} isLoading={formik.isSubmitting} />
        </div>
      </ThemeContainer>
    </div>
  );
};
