import gql from "graphql-tag";

export interface PaymentMethodsResults {
  paymentGateways: {
    nodes: PaymentMethod[];
  };
}
export interface PaymentMethod {
  id: string;
  title: string;
  icon: string;
}

export interface ShippingMethodsResults {
  cart: {
    availableShippingMethods: {
      rates: ShippingMethod[];
    }[];
  };
}
export interface CountriesResults {
  countries: {
    code: string;
    country: string;
  }[];
}

export interface CountrysMethodsResults {
  shippingZones: {
    zones: CountrysMethod[];
  };
}

export interface ShippingMethod {
  id: string;
  cost: string;
  label: string;
  methodId: string;
  instanceId: string;
}

export interface NewShippingMethod {
  id: string;
  method_title: string;
  min_amount: number;
  cost: number;
  label: string;
}

export interface CountrysMethod {
  cost: number;
  country: string;
  id: string;
  label: string;
  min_amount: number;
  method_title?: string;
}

export const PAYMENT_METHODS = gql`
  query paymentMethods {
    paymentGateways {
      nodes {
        id
        title
        icon
      }
    }
  }
`;

export const SHIPPING_METHODS = gql`
  query shippingMethods {
    cart {
      availableShippingMethods {
        rates {
          cost
          id
          instanceId
          label
          methodId
        }
      }
    }
  }
`;
