import React from "react";
import { Spinner } from "reactstrap";
import { useBodyOverFlow } from "../../lib/hooks/useBodyOverflow";

import "./LoaderOverlay.scss";

export const LoaderOverlay = () => {
  useBodyOverFlow();

  return (
    <div className="LoaderOverlay d-flex justify-content-center align-items-center">
      <Spinner style={{ width: 70, height: 70 }} />
    </div>
  );
};
