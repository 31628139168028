import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";

import { useRouter } from "../../hooks/useRouter";
import { useAuthContext } from "../AuthContext/AuthContext";
import { useCouponMutations } from "../../hooks/useCouponMutations";

import * as cartQueries from "../../../queries/cartQueries";
import * as cartMutations from "../../../queries/mutations/cartMutations";

import { CartProduct } from "../../../types/cartTypes";
import { CartContext, CartContextType, CartUiState } from "./CartContext";
import { LoaderOverlay } from "../../../components/LoaderOverlay/LoaderOverlay";

interface CartContextProviderProps {
  children: React.ReactNode | null;
}

export const CartContextProvider = (props: CartContextProviderProps) => {
  const router = useRouter();
  const auth = useAuthContext();
  const coupons = useCouponMutations();

  const [cartUiState, setCartUiState] = useState<CartUiState>({
    open: false,
    productAdded: false,
  });

  const {
    loading,
    error,
    data: cartData,
    refetch,
  } = useQuery<cartQueries.GetCartResponse>(cartQueries.getCart);

  const [
    updateItemQuantities,
    { loading: isUpdatingProduct, error: updateErrors },
  ] = useMutation(cartMutations.UPDATE_ITEM_QUANTITIES);

  const [addItem] = useMutation(cartMutations.ADD_ITEM_TO_CART);

  const [
    emptyCartMutation,
    { loading: isEmptyLoading, error: emptyCartErrors },
  ] = useMutation(cartMutations.EMPTY_CART);

  const [updateShipping, { loading: isUpdatingShippingMethod }] = useMutation<
    cartMutations.UpdateShippingMethodResponse,
    cartMutations.UpdateShippingMethodVariables
  >(cartMutations.updateShippingMethod);

  const refetchCart = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    // updateShippingMethod([shippingMethod.id]);
    refetchCart();
  }, [auth.isAuthenticated, refetchCart]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetCartUiState();
    }, 2300);

    return () => {
      clearTimeout(timeout);
    };
  }, [cartUiState.open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetCartUiState();
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetCartUiState = () => {
    const userCart = document.getElementById("user-cart");
    const hasForceOpen = userCart && !!userCart.getAttribute("force-open");
    setCartUiState((prevState) => ({
      ...prevState,
      open: !!hasForceOpen,
      productAdded: false,
    }));
  };

  const updateProductQuantity = (productKey: string, value: number) => {
    try {
      return updateItemQuantities({
        variables: {
          input: {
            clientMutationId: "cart-quantity-update",
            items: [{ key: productKey, quantity: value }],
          },
        },
        update(cache, { data: { updateItemQuantities: updateData } }) {
          const { cart }: any = cache.readQuery({ query: cartQueries.getCart });
          const newItems =
            value === 0
              ? cart.contents.nodes.filter((item: CartProduct) => {
                  return item.key !== updateData.items[0].key;
                })
              : cart.contents.nodes.map((item: CartProduct) => {
                  return {
                    ...item,
                    quantity:
                      item.key === updateData.items[0].key
                        ? updateData.items[0].quantity
                        : item.quantity,
                  };
                });

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cart,
                isEmpty: updateData.cart.isEmpty,
                subtotal: updateData.cart.subtotal,
                total: updateData.cart.total,
                contents: {
                  ...cart.contents,
                  itemCount: updateData?.cart?.contents?.itemCount,
                  nodes: newItems,
                },
              },
            },
          });
        },
      });
    } catch (e) {}
  };

  const addProduct = (
    productID: number,
    quantity: number,
    variation?: number
  ) => {
    return addItem({
      variables: {
        clientMutationId: "add-product-to-cart",
        productId: productID,
        quantity,
        variationId: variation,
      },
      update(
        cache,
        {
          data: {
            addToCart: { cart },
          },
        }
      ) {
        cache.writeQuery({
          query: cartQueries.getCart,
          data: { cart: cart },
        });

        setCartUiState((prev) => {
          return {
            ...prev,
            open: true,
            productAdded: true,
          };
        });
      },
    });
  };

  const emptyCart = () => {
    try {
      emptyCartMutation();
      refetchCart();
    } catch (error) {
      console.error(error);
    }
  };

  const updateShippingMethod = (shippingMethodId: string[]) => {
    try {
      updateShipping({
        variables: {
          shippingMethods: shippingMethodId,
        },
        update(cache, data) {
          const { cart }: any = cache.readQuery({ query: cartQueries.getCart });
          const updatedCartData = data.data?.updateShippingMethod.cart;

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cart,
                total: updatedCartData?.total,
                shippingTotal: updatedCartData?.shippingTotal,
                chosenShippingMethod: updatedCartData?.chosenShippingMethod,
              },
            },
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const applyCoupon = async (coupon: string) => {
    try {
      return coupons.applyCoupon({
        variables: {
          coupon: coupon,
        },
        update(cache, data) {
          const { cart }: any = cache.readQuery({ query: cartQueries.getCart });
          const updatedCart = data?.data?.applyCoupon?.cart;
          const total = updatedCart?.total;
          const appliedCoupons = updatedCart?.appliedCoupons ?? [];

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cart,
                total: total,
                appliedCoupons: appliedCoupons,
              },
            },
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const removeCoupon = async (coupon: string) => {
    try {
      await coupons.removeCoupon({
        variables: {
          coupon: coupon,
        },
        update(cache, data) {
          const { cart }: any = cache.readQuery({ query: cartQueries.getCart });
          const updatedCart = data?.data?.removeCoupons?.cart;
          const appliedCoupons = updatedCart?.appliedCoupons?.nodes ?? [];
          const total = updatedCart?.total;

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cart,
                total: total,
                appliedCoupons: appliedCoupons,
              },
            },
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const context: CartContextType = {
    isLoading: loading,
    isEmptyLoading,
    isUpdatingProduct: isUpdatingProduct,
    isUpdatingShippingMethod: isUpdatingShippingMethod,
    isApplyingCoupon: coupons?.applyCouponRes?.loading,
    isRemovingCoupon: coupons?.removeCouponRes?.loading,

    total: cartData?.cart?.total ?? "",
    isEmpty: cartData?.cart.isEmpty ?? false,
    subtotal: cartData?.cart.subtotal ?? "",
    chosenShippingMethod: cartData?.cart?.chosenShippingMethod ?? "",
    shippingTotal: cartData?.cart?.shippingTotal ?? "",
    appliedCoupons: cartData?.cart?.appliedCoupons?.nodes ?? [],
    hasCoupons: !!cartData?.cart?.appliedCoupons?.nodes?.length,
    itemCount: cartData?.cart?.contents?.itemCount ?? null,
    items: cartData?.cart?.contents?.nodes ?? ([] as cartQueries.CartNode[]),

    error: error || updateErrors || emptyCartErrors, // prettier-ignore
    cartUiState,

    setCartUiState,
    addProduct,
    updateShippingMethod,
    refetch,
    updateProductQuantity,
    applyCoupon,
    removeCoupon,
    emptyCart,
  };

  return (
    <CartContext.Provider value={context}>
      {coupons?.removeCouponRes?.loading && <LoaderOverlay />}

      {isUpdatingShippingMethod && <LoaderOverlay />}

      {props.children}
    </CartContext.Provider>
  );
};
