import { CartProduct } from "../../types/cartTypes";
import { OrderItem } from "../../queries/ordersQueries";
import { ProductPricesType } from "../context/CartContext/CartContext";
import { CartNode } from "../../queries/cartQueries";

export const getProductPrices = (
  item: CartProduct | OrderItem | CartNode
): ProductPricesType => {
  const hasVariations = item.product?.node?.type === "VARIABLE";

  return {
    sale: hasVariations
      ? item.variation?.node?.salePrice
      : item.product?.node?.salePrice,
    regular: hasVariations
      ? item.variation?.node?.regularPrice
      : item.product?.node?.regularPrice
  };
};
