import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import { useAvailableCountrys } from "../../../lib/hooks/useAvailableCountrys";
import { errors, formInputs, messages } from "../../../lib/translation/strings";

interface Props {
  name: string;
  initialValue?: string;
  hasError: boolean | undefined;
  onSelect: (v: string) => void;
  errorMessage: string | undefined;
  onComplete?: (state: boolean) => void;
}

export const SelectCountry = (props: Props) => {
  const { data, error, loading } = useAvailableCountrys();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.onComplete) {
      props.onComplete(loading);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <p>{t(errors.wentWrong)}</p>;
  }

  return (
    <FormGroup>
      <Input
        disabled={loading}
        type="select"
        placeholder={t(formInputs.state)}
        id={props.name}
        invalid={props.hasError}
        onChange={(e) => props.onSelect(e.target.value)}
        value={props.initialValue ? props.initialValue : ""}
      >
        <option value="">
          {loading ? t(messages.pleaseWait) : t(formInputs.state)}
        </option>
        {data?.map((item: { code: string; country: string }) => (
          <option key={`${item.code}-register-city`} value={item.code}>
            {item.country}
          </option>
        ))}
      </Input>
      <FormFeedback>{props.errorMessage}</FormFeedback>
    </FormGroup>
  );
};
