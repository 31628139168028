export const getProductVariationSlug = (
  productVariations?: any[],
  value?: string
) => {
  if (!value || !productVariations) {
    return;
  }

  const variation = productVariations.find((item) =>
    item?.attributes?.nodes?.find(
      (n: any) => n.name === "pa_ngjyra" && n.value === value
    )
  );

  if (!variation) {
    return;
  }

  return variation?.image?.slug;
};
