import React, { useEffect, useState } from "react";
import {
  MenuSidebar,
  MenuSidebarName,
  MenuSidebarType
} from "./MenuSidebarContext";
import { useRouter } from "../../hooks/useRouter";

interface Props {
  children: React.ReactNode;
}

type AvaliableSidebars = MenuSidebarName;

interface AvailableSidebarsType {
  [key: string]: boolean;
}

const initialState = {
  cart: false,
  wishlist: false,
  search: false
};

export const MenuSidebarContextProvider = (props: Props) => {
  const router = useRouter();
  const [sidebars, setSidebars] = useState<AvailableSidebarsType>(initialState);

  useEffect(() => {
    setSidebars(initialState);
  }, [router.location.pathname]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (sidebars.cart || sidebars.wishlist || sidebars.search) {
      document.body.style.overflow = "hidden";
      document.getElementById("App")?.classList.add("App--show-backdrop");
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById("App")?.classList.remove("App--show-backdrop");
    };
  }, [sidebars]); // eslint-disable-line react-hooks/exhaustive-deps

  const openSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: true
    });
  };

  const closeSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: false
    });
  };

  const toggleSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: !sidebars[name]
    });
  };

  const context: MenuSidebarType = {
    cart: sidebars.cart,
    wishtlist: sidebars.wishlist,
    search: sidebars.search,
    closeSidebar,
    openSidebar,
    toggleSidebar
  };

  return (
    <MenuSidebar.Provider value={context}>
      {props.children}
    </MenuSidebar.Provider>
  );
};
