import gql from "graphql-tag";

export const GET_VIEWER_QUERY = gql`
  query getViewerQuery {
    viewer {
      id
      name
      email
      userId
      lastName
      username
      firstName
      wishListProducts {
        wishlistProducts
      }
    }
  }
`;

export const GET_USER_ADDRESSES_QUERY = gql`
  query getUserAddressesQuery($id: ID) {
    customer(id: $id) {
      billing {
        address1
        city
        firstName
        lastName
        phone
        country
      }
      shipping {
        address1
        city
        firstName
        lastName
        phone
        country
      }
    }
  }
`;
