import React from "react";
import { Route, Switch } from "react-router-dom";

import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";

import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { AppRoutes, RouteType } from "./AppRoutes";

import { Page404 } from "../pages/Page404/Page404";

export const Routes = () => {
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  return (
    <Switch>
      {AppRoutes.map(r => {
        const { type, path, ...rest } = r;

        if (type === RouteType.PUBLIC) {
          return (
            <PublicRoute
              {...rest}
              key={`${r.path}`}
              path={`${langPrefix}/${r.path}`}
            />
          );
        }

        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute
              {...rest}
              key={`${r.path}`}
              path={`${langPrefix}/${r.path}`}
            />
          );
        }

        return (
          <Route {...rest} key={`${r.path}`} path={`${langPrefix}/${r.path}`} />
        );
      })}

      <Route component={Page404} />
    </Switch>
  );
};
