import { useMemo } from "react";
// import { RouteComponentProps } from "react-router-dom";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router-dom";

import queryString from "query-string";

// interface RouterType
//   extends RouteComponentProps<{ confirmationCode: string }> {
//   others: any;
// }

export function useRouter<T = {}>() {
  const params = useParams<any>();

  const location = useLocation();

  const history = useHistory();

  const match = useRouteMatch();

  // Return our custom router object

  // Memoize so that a new object is only returned if something changes

  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level

      push: history.push,

      replace: history.replace,

      pathname: location.pathname,

      // Merge params and parsed query string into single "query" object

      // so that they can be used interchangeably.

      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }

      query: {
        ...queryString.parse(location.search), // Convert string to object

        ...params
      },

      // Include match, location, history objects so we have

      // access to extra React Router functionality if needed.

      match,

      location,

      history
    };
  }, [params, match, location, history]);
}
