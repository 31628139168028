import React, { useEffect } from "react";
// import { Input } from "reactstrap";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";

import {
  CountrysMethodsResults,
  CountrysMethod,
  NewShippingMethod,
} from "../../../queries/checkoutQueries";
import { fields, messages } from "../../../lib/translation/strings";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { GET_AVAILABLE_SHIPPING_METHODS } from "../../../queries/generalQueries";
import ShippingMethodLoader from "./ShippingMethodLoader";

import "./ShippingMethod.scss";

interface Props {
  formik: CheckoutFormik;
}

export const ShippingMethod = (props: Props) => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();
  const country = props.formik.values.billing.country ?? "";
  const shippingMethods = useQuery<CountrysMethodsResults>(
    GET_AVAILABLE_SHIPPING_METHODS
  );

  const shippingMethodsData = shippingMethods.data?.shippingZones?.zones ?? [];

  const beforeFinalShippings = shippingMethodsData?.filter((item) => {
    return item.country === country;
  });

  const amount = beforeFinalShippings.filter((item) =>
    item.id.includes("free_shipping")
  );

  const getShippingMethods = () => {
    if (!country) {
      const res = shippingMethodsData?.filter((item) => item.country === "XK");
      return res;
    }

    const res = beforeFinalShippings?.filter(
      (item) => parseInt(cartCtx.total) < item.min_amount
    );

    if (res.length === 0) {
      return beforeFinalShippings?.filter((item) =>
        item.id.includes("free_shipping")
      );
    }

    return beforeFinalShippings.filter((item) => item.id.includes("flat_rate"));
  };

  const finalShippingMethods = getShippingMethods();

  const handleShippingChange = (shippingMethod: NewShippingMethod) => {
    if (!shippingMethod) {
      return;
    }

    cartCtx.updateShippingMethod([shippingMethod.id]);
    props.formik.setFieldValue("shippingMethod", {
      id: shippingMethod.id,
      label: shippingMethod.label,
      cost: shippingMethod.cost,
    });
    props.formik.setFieldTouched("shippingMethod");
  };

  useEffect(() => {
    handleShippingChange(finalShippingMethods[0] as any);
  }, [country, shippingMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ShippingMethod">
      <HandleLoadingState
        loading={shippingMethods.loading}
        loadingPlaceholder={<ShippingMethodLoader />}
      >
        <ApolloErrorGuard error={shippingMethods.error}>
          <div>
            <h4 className="ShippingMethod__title">
              {t(fields.shippingMethod)}
            </h4>
            {finalShippingMethods?.map((shippingMethod: CountrysMethod) => {
              return (
                <div className="ShippingMethod__item" key={shippingMethod.id}>
                  <label htmlFor={`pm-${shippingMethod.id}`}>
                    {/* <Input
                        type="radio"
                        id={`pm-${item.id}`}
                        name="shippingMethod"
                        value={item.id}
                        hidden
                        onChange={() => handleShippingChange(item)}
                      /> */}
                    <span className="name">
                      {shippingMethod.label.replace(
                        shippingMethod.country,
                        " "
                      )}
                    </span>
                    {shippingMethod.cost && shippingMethod.cost !== 0 ? (
                      <span className="price">
                        {shippingMethod?.cost?.toFixed(2)}€
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="ShippingMethod__terms">
            <h4 className="ShippingMethod__title">
              {t(messages.termsOfShipping)}
            </h4>
            <label>
              {t(messages.transportInfo, { amount: amount[0]?.min_amount })}{" "}
              {amount[0]?.country === "XK" ? "1.5" : "5"}€
            </label>
            <label>
              {t(messages.transportInfoUpTo, { amount: amount[0]?.min_amount })}{" "}
              {t(messages.free).toLowerCase()}
            </label>
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
