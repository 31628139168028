import React from "react";
import { Spinner } from "reactstrap";

import "./LoadingPlaceholder.scss";

export const LoadingPlaceholder = () => {
  return (
    <div className="LoadingPlaceholder">
      <Spinner style={{ width: 70, height: 70 }} />
    </div>
  );
};
