import React from "react";
import { NavLink } from "react-router-dom";
import { HeadingTitle } from "../HeadingTitle/HeadingTitle";

import "./InfoMessage.scss";

interface Props {
  title: string;
  description?: string;

  buttonTitle?: string;
  buttonLink?: string;
  onButtonClick?: () => void;
}

export const InfoMessage = (props: Props) => {
  return (
    <div className="InfoMessage">
      <HeadingTitle title="Asnjë rezultat nuk është gjetur!" />
      <NavLink className="InfoMessage__link" to={props.buttonLink || "/"}>
        Në rregull
      </NavLink>
    </div>
  );
};
