import React from "react";
import { useTranslation } from "react-i18next";

import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { fields } from "../../../lib/translation/strings";
import { getProductPrices } from "../../../lib/helpers/getProductPrices";
import { ProductPrices } from "../../shared/ProductPrices/ProductPrices";

import { Image } from "../../shared/Image/Image";

import "./OrderDetails.scss";

export const OrderDetails = () => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  return (
    <div className="OrderDetails">
      <div className="OrderDetails__table">
        <h4 className="OrderDetails__table__title">{t(fields.bagSummary)}</h4>
        <ul className="CartSidebar-list">
          {cartCtx?.items?.map((item, i) => {
            const product = item.product;

            const prices = getProductPrices(item);

            // const getAttributes = (value: string) => {
            //   const res = item?.variation?.node?.attributes?.nodes?.filter(
            //     (item) => {
            //       return item.name === value;
            //     }
            //   );

            //   return res;
            // };

            return (
              <li
                key={`${item?.product?.id}-${i}`}
                className="CartSidebar-list-item"
              >
                <div className="CartSidebar-list-item-image">
                  <Image
                    src={product?.node?.image?.mediaItemUrl}
                    alt={item.product.node?.name}
                  />
                </div>
                <div className="CartSidebar-list-item-info">
                  <h4>{product?.node?.name}</h4>
                  <div className="d-flex align-items-center">
                    <h5>{t(fields.quantity)}:</h5>
                    <span>{item.quantity}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5>{t(fields.price)}:</h5>
                    <ProductPrices
                      onSale={item.product?.node?.onSale}
                      sale={prices.sale}
                      regular={prices.regular}
                    />
                  </div>
                  {/* <div className="d-flex align-items-center">
                    <h5>{t(getAttributes("pa_madhesia")[0]?.name)}:</h5>
                    {getAttributes("pa_madhesia")[0]?.value}
                  </div>
                  <div className="d-flex align-items-center">
                    <h5>{t(getAttributes("pa_ngjyra")[0]?.name)}:</h5>
                    {getAttributes("pa_ngjyra")[0]?.value}
                  </div> */}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
