import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ForgotPasswordFields } from "../../pages/ForgotPassword/ForgotPassword";
import { formValidation } from "../translation/strings";


interface UseForgotPasswordFormOptions {
  initialValues: ForgotPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ForgotPasswordFields>
    ) => Promise<any>;
  }

export const useForgotPasswordFormik = (
  options: UseForgotPasswordFormOptions
) => {
  const {t} = useTranslation();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(formValidation.validEmail))
      .required(t(formValidation.email)),
    emailConfirm: Yup.string()
      .email(t(formValidation.validEmail))
      .required(t(formValidation.email))
      .oneOf([Yup.ref("email")], t(formValidation.differentEmail))
  });

  return useFormik({
    initialValues: {
      ...options.initialValues
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type ForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>;
