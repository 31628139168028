import { ApolloError } from "apollo-boost";
import { GraphQLError } from "graphql";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { Coupon } from "../../../types/generalTypes";
import { ApolloQueryResult } from "apollo-client/core/types";
import { CartNode } from "../../../queries/cartQueries";

export interface CartUiState {
  open: boolean;
  productAdded: boolean;
}

export interface CartContextType {
  // Loading states
  isLoading: boolean;
  isUpdatingProduct: boolean;
  isUpdatingShippingMethod: boolean;
  isRemovingCoupon: boolean;
  isApplyingCoupon: boolean;
  isEmptyLoading: boolean;

  // Cart details
  isEmpty: boolean | undefined;
  hasCoupons: boolean;
  total: string;
  subtotal: string;
  shippingTotal: string;
  chosenShippingMethod: string;
  itemCount: number | null;
  appliedCoupons: Coupon[];
  items: CartNode[] | undefined;

  // other
  error?: ApolloError | ReadonlyArray<GraphQLError>;
  cartUiState: CartUiState;

  // methods
  refetch(variables?: any): Promise<ApolloQueryResult<any>>;
  addProduct: (
    productID: number,
    quantity: number,
    variation?: number | undefined
  ) => Promise<any>;
  updateShippingMethod: (ids: string[]) => void;
  updateProductQuantity: (key: string, value: number) => void;
  applyCoupon: (ids: string) => Promise<any>;
  removeCoupon: (ids: string) => Promise<any>;
  setCartUiState: Dispatch<SetStateAction<CartUiState>>;
  emptyCart: () => void;
}

export interface ProductPricesType {
  sale: string | undefined | null;
  regular: string | undefined;
}

const CartContextValues: CartContextType = {
  isLoading: false,
  isUpdatingProduct: false,
  isEmptyLoading: false,
  isUpdatingShippingMethod: false,
  isApplyingCoupon: false,
  isRemovingCoupon: false,

  total: "",
  subtotal: "",
  isEmpty: undefined,
  hasCoupons: false,
  shippingTotal: "",
  chosenShippingMethod: "",
  itemCount: null,
  items: [],
  appliedCoupons: [],

  error: undefined,
  cartUiState: {
    open: false,
    productAdded: false,
  },

  setCartUiState: () => {},
  refetch: () => new Promise<ApolloQueryResult<any>>((resolve) => resolve()),
  addProduct: async () => {},
  updateShippingMethod: () => {},
  updateProductQuantity: () => {},
  applyCoupon: () => new Promise<any>((resolve) => resolve()),
  removeCoupon: () => new Promise<any>((resolve) => resolve()),
  emptyCart: () => {},
};
export const CartContext = React.createContext<CartContextType>(
  CartContextValues
);

export const useCartContext = () => useContext(CartContext);
