import React from "react";
import queryString from "query-string";
import { Row } from "reactstrap";

import { useRouter } from "../../lib/hooks/useRouter";

import { SearchResults } from "../../components/SearchResults/SearchResults";
import { Container } from "../../components/Container/Container";

export const SearchPage = () => {
  const { location } = useRouter();
  const params = queryString.parse(location.search);
  const searchValue = params.s || "";

  const keyword =
    searchValue && Array.isArray(searchValue)
      ? searchValue.join()
      : searchValue;

  return (
    <div className="SearchPage">
      <Container>
        <Row>
          <SearchResults searchTerm={keyword} />
        </Row>
      </Container>
    </div>
  );
};
