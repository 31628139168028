import gql from "graphql-tag";

export const SUBSCRIBE = gql`
  mutation subscribe($clientMutationId: String!, $email: String!) {
    mailchimpSubscription(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      message
    }
  }
`;

export const SUBSCRIBE_COUPON = gql`
  mutation subscribeCoupon($clientMutationId: String!, $email: String!) {
    mailchimpCouponSubscription(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      message
    }
  }
`;

export const JOBS = gql`
  mutation JOBS(
    $email: String!
    $job: String!
    $lastName: String!
    $name: String!
    $phone: String!
    $file: Float!
    $message: String!
  ) {
    applyForm(
      input: {
        email: $email
        file: $file
        job: $job
        last_name: $lastName
        name: $name
        phone: $phone
        message: $message
      }
    ) {
      name
    }
  }
`;

export const REVIEWS = gql`
  mutation Review(
    $rating: Int!
    $author: String!
    $commentOn: Int!
    $content: String!
  ) {
    writeReview(
      input: {
        rating: $rating
        author: $author
        content: $content
        commentOn: $commentOn
      }
    ) {
      review {
        author {
          node {
            id
            name
          }
        }
        content
        commentedOn {
          node {
            id
            ... on VariableProduct {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FEEDBACK = gql`
  mutation Feedback($feedback: String!, $message: String!, $review: Float!) {
    feedbackForm(
      input: { feedback: $feedback, message: $message, review: $review }
    ) {
      result
    }
  }
`;
