import gql from "graphql-tag";

export const GET_AVAILABLE_COUNTRYS = gql`
  query getAvailableCountrys {
    countries {
      code
      country
    }
  }
`;

export const GET_AVAILABLE_CITIES = gql`
  query getAvailableCities {
    themeOptions {
      themeOptions {
        cities
      }
    }
  }
`;

export const GET_AVAILABLE_SHIPPING_METHODS = gql`
  query getAvailableShippingMethods {
    shippingZones {
      zones {
        id
        cost
        country
        label
        min_amount
      }
    }
  }
`;

export const GET_FOOTER = gql`
  query getFooter {
    generalOptions {
      generalOptions {
        footer {
          address
          email
          addressEn
          youtube
          twitter
          phone
          instagram
          facebook
        }
      }
    }
  }
`;

export const GET_FEEDBACK_OPTIONS = gql`
  query getFeedbackOptions {
    generalOptions {
      generalOptions {
        feedbackForm {
          feedbackOptions {
            title
          }
        }
      }
    }
  }
`;

export const GET_JOBS_OPTIONS = gql`
  query getJOBSOptions {
    generalOptions {
      generalOptions {
        applyForm {
          jobOptions {
            title
          }
        }
      }
    }
  }
`;

export const GET_TRANSPORT_CONTENT = gql`
  query getTransportContent {
    themeOptions {
      themeOptions {
        transport
      }
    }
  }
`;

export const GET_FOOTER_OPTIONS = gql`
  query getFooterOptions {
    generalOptions {
      generalOptions {
        footer {
          address
          email
          facebook
          instagram
          phone
          twitter
          youtube
        }
      }
    }
  }
`;

export const GET_CONTACT_OPTIONS = gql`
  query getContactOptions {
    generalOptions {
      generalOptions {
        contact {
          content
          googleApiKey
          latitude
          longitude
        }
      }
    }
  }
`;

export interface FAQItem {
  title: string;
  description: string;
}

export interface FAQResponse {
  generalOptions: {
    generalOptions: {
      faq: {
        list: FAQItem[];
      };
    };
  };
}

export const GET_FAQ = gql`
  query FAQQuery {
    generalOptions {
      generalOptions {
        faq {
          list {
            title
            description
          }
        }
      }
    }
  }
`;

export interface Page {
  id: string;
  title: string;
  content: string;
  databaseId: number;
  pageEnglish: {
    descriptionEn: string;
    titleEn: string;
  };
}

export const GET_PAGE_BY_ID = gql`
  query getPageByID($id: Int) {
    pages(where: { id: $id }) {
      nodes {
        id
        title
        content
        databaseId
        pageEnglish {
          descriptionEn
          titleEn
        }
      }
    }
  }
`;

export const GET_ATTRIBUTES_ORDERED = gql`
  query orderedAttributes {
    generalOptions {
      generalOptions {
        orderAttributes
      }
    }
  }
`;

export const GET_PRODUCT_RETURN_CONDITIONS = gql`
  query productReturnConditions {
    generalOptions {
      generalOptions {
        returnConditions
        returnConditionsEn
      }
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query getPageBySlug($slug: String) {
    pageBy(uri: $slug) {
      featuredImage {
        node {
          id
          sourceUrl(size: LARGE)
        }
      }
      slug
      title
      content
    }
  }
`;
