import { useEffect } from "react";

export const useBodyOverFlow = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
