import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formValidation } from "../translation/strings";
import { Feedback } from "../../layout/Footer/Footer";

interface UseRegisterFormOptions {
  initialValues?: Feedback;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<Feedback>
  ) => Promise<any>;
}

export const useFeedbackFormik = (options: UseRegisterFormOptions) => {
  const { t } = useTranslation();

  const FeedbackSchema = Yup.object().shape({
    feedback: Yup.string().required(t(formValidation.selectVariation)),
    message: Yup.string().required(t(formValidation.message)),
  });

  return useFormik({
    initialValues: {
      feedback: "",
      message: "",
      review: 0,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: FeedbackSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type FeedbackFormik = ReturnType<typeof useFeedbackFormik>;
