import React, { useEffect } from "react";
import i18n from "i18next";
import { Routes } from "./routes/Routes";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { useMenuSidebar } from "./lib/context/MenuSidebarContext/MenuSidebarContext";
import { SearchSidebar } from "./layout/SearchSidebar/SearchSidebar";
import { MenuContextProvider } from "./lib/context/MenuContext/MenuContextProvider";
import { NotificationContextProvider } from "./lib/context/NotificationContext/NotificationContextProvider";

import { Header } from "./layout/Header/Header";
import { Footer } from "./layout/Footer/Footer";
import { CartSidebar } from "./layout/CartSidebar/CartSidebar";
import { WishlistSidebar } from "./layout/WishlistSidebar/WishlistSidebar";
import { MenuResponsive } from "./layout/Header/Menu/MenuResponsive/MenuResponsive";

import "./App.scss";

// import { Advertisement } from "./components/Advertisement/Advertisement";

export const App = () => {
  const history = useHistory();

  useScrollToTop();
  const menuSidebar = useMenuSidebar();
  const tagManagerArgs = {
    gtmId: "GTM-KRXNHQG",
  };

  TagManager.initialize(tagManagerArgs);

  const handleOnClick = () => {
    menuSidebar.closeSidebar("wishlist");
    menuSidebar.closeSidebar("cart");
  };

  useEffect(() => {
    return history.listen((location) => {
      const urlLang = location.pathname.startsWith("/en/") ? "en" : "sq";

      if (urlLang === "en" && i18n.language !== "en") {
        i18n.changeLanguage("en");
      } else if (urlLang === "sq" && i18n.language !== "sq") {
        i18n.changeLanguage("sq");
      }
    });
  }, [history]);

  return (
    <div className="App" id="App">
      <NotificationContextProvider>
        <div className="App__backdrop" onClick={handleOnClick} />
        <MenuContextProvider>
          <Header />
          <MenuResponsive />
        </MenuContextProvider>
        <div className="Main_content">
          <Routes />
        </div>
        <Footer />
        <WishlistSidebar />
        <CartSidebar />
        <SearchSidebar />
      </NotificationContextProvider>
    </div>
  );
};
