import React, { useRef } from "react";
import cn from "classnames";
import { Icon } from "../../components/shared/Icon/Icon";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { Search } from "../Header/Search/Search";
import { actions } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

import "./SearchSidebar.scss";

export const SearchSidebar = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const menuSidebar = useMenuSidebar();

  return (
    <div
      ref={ref}
      className={cn(
        "SearchSidebar",
        menuSidebar.search && "SearchSidebar--opened"
      )}
    >
      <div className="SearchSidebar-head">
        <p>{t(actions.search)}</p>
        <span role="button" onClick={() => menuSidebar.closeSidebar("search")}>
          <Icon icon="x" />
        </span>
      </div>
      <div className="SearchSidebar-body">
        <Search />
      </div>
    </div>
  );
};
