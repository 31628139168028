import React, { useContext } from "react";

export interface SidebarContextType {
  isOpened: boolean;
  toggleSidebar: () => void;
  openSidebar: () => void;
  closeSidebar: () => void;
}

const defaultSidebarContext: SidebarContextType = {
  isOpened: false,
  toggleSidebar: () => {},
  openSidebar: () => {},
  closeSidebar: () => {}
};

export const SidebarContext = React.createContext<SidebarContextType>(
  defaultSidebarContext
);

export function useSidebarContext() {
  return useContext(SidebarContext);
}
