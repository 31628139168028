import { useQuery } from "react-apollo";
import {
  ChosenAttributesInput,
  ProductTaxonomyEnum
} from "../../types/generalTypes";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRouter } from "./useRouter";
import { useSearchParams } from "./useSearchParams";
import { useShopHandlers } from "./useShopHandlers";

import * as shopQueries from "../../queries/shop";

export const useAttributeFiltersQuery = (args?: ChosenAttributesInput) => {
  const router = useRouter();
  const params = useParams<any>();
  const searchParams = useSearchParams();
  const shopHandlers = useShopHandlers();

  const categoryTerms = {
    taxonomy: ProductTaxonomyEnum.PRODUCTCATEGORY,
    terms: params.childCategory || params.parentCategory || ""
  };

  const initialCategoryTerms = args ? [categoryTerms, args] : [categoryTerms];

  const filteredCategoryTerms = initialCategoryTerms.filter(
    term => term.terms !== ""
  );

  const { loading, error, data, refetch } = useQuery<
    shopQueries.GetAttributeFiltersResponse,
    shopQueries.GetAttributeFiltersVariables
  >(shopQueries.getAttributeFilters, {
    variables: {
      chosenAttributes: filteredCategoryTerms
    }
  });

  // // On parent_category/child_category change, refetch attribute filters
  // useEffect(() => {
  //   refetch({
  //     chosenAttributes: filteredCategoryTerms
  //   });
  // }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //Refetch attributes on query params change
  useEffect(() => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(searchParams);
    refetch({
      chosenAttributes: [
        ...filteredCategoryTerms,
        ...taxonomyFilters
      ] as ChosenAttributesInput[]
    });
  }, [router.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    error,
    data,
    refetch
  };
};
