import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Container, Row, Col } from "reactstrap";

import { ListOfProducts } from "../ListOfProducts/ListOfProducts";
import { ApolloErrorGuard } from "../shared/ApolloErrorGuard/ApolloErrorGuard";

import * as searchQueries from "../../queries/search";

import "./SearchResults.scss";

interface Props {
  searchTerm: string;
}

const postsLimit = 15;

export const SearchResults = (props: Props) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { loading, error, data, fetchMore } = useQuery<
    searchQueries.SearchAutocompleteProductsResponse,
    searchQueries.SearchProductVariables
  >(searchQueries.searchProducts, {
    variables: {
      term: props.searchTerm || "",
      limit: postsLimit,
      after: "",
    },
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);

    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.products.pageInfo.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo,
          },
        };
      },
    });
  };

  const products = data?.products?.nodes ?? [];
  const productInfo = !!data?.products?.pageInfo?.hasNextPage;

  return (
    <div className="SearchResults">
      <ApolloErrorGuard error={error}>
        <Container>
          <Row>
            <Col xs="12" md="12">
              <h1 className="SearchResults__title">
                Kërkoni
                <span className="SearchResults__term">
                  Rezultatet per "{props.searchTerm}"
                </span>
              </h1>

              <ListOfProducts
                className={loading ? "SearchResults--products-loading" : ""}
                products={products}
                loading={loading}
                loadingPlaceholderNumber={postsLimit}
                isLoading={isLoadingMore}
                onLoadMore={handleLoadMore}
                pageInfo={productInfo}
              />
            </Col>
          </Row>
        </Container>
      </ApolloErrorGuard>
    </div>
  );
};
