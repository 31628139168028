import React from "react";
import { useQuery } from "react-apollo";
import Slider from "react-slick";
import { GET_TYPE_B_CAMPAIGNS } from "../../../queries/campaignsQueries";
import { SlideLoader } from "./SlideLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";

import "./SlideB.scss";

export const SlideB = () => {
  const slider = useQuery(GET_TYPE_B_CAMPAIGNS);

  const sliderData =
    slider.data?.generalOptions?.generalOptions?.homeAds
      ?.b1BannerSliderCampaigns ?? {};

  const settings = {
    infinite: false,
    autoplay: false,
    arrows: false,
    fade: false,
  };

  if (!Object.keys(sliderData).length || !sliderData) {
    return null;
  }

  return (
    <div className="SlideB">
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<SlideLoader />}
      >
        <ApolloErrorGuard error={slider.error}>
          <Slider {...settings}>
            <div key={sliderData?.id} className="SlideB__item">
              <img src={sliderData?.featuredImage?.node?.mediaItemUrl} alt="" />
              {/* eslint-disable-next-line */}
              <a
                href={sliderData?.campaignsOptions?.product?.link}
                className="SlideB__content"
              />
            </div>
          </Slider>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
