import gql from 'graphql-tag';

export const CONTACT_FORM = gql`
  mutation ContactForm(
    $nameSurname: String!
    $email: String!
    $message: String!
  ) {
    contactForm(
      input: {
        clientMutationId: "contact-form"
        nameSurname: $nameSurname
        email: $email
        message: $message
      }
    ) {
      message
    }
  }
`;
