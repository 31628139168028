import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";
import { ThemeRedirect } from "../components/shared/ThemeRedirect/ThemeRedirect";

export const PublicRoute = (props: RouteProps) => {
  const authCtx = useAuthContext();

  if (authCtx.isAuthenticated) {
    return <ThemeRedirect to="/" />;
  }

  return <Route {...props} />;
};
