import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { CartNode } from "../../queries/cartQueries";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { removeHTMLEntities } from "../../lib/helpers/removeHTMLEntities";
import { useHistory } from "react-router";
import { actions, fields, messages } from "../../lib/translation/strings";
import { getProductPrices } from "../../lib/helpers/getProductPrices";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

import { ProductPrices } from "../../components/shared/ProductPrices/ProductPrices";
import { Icon } from "../../components/shared/Icon/Icon";
import { Image } from "../../components/shared/Image/Image";
import { Quantity } from "../../components/shared/QuantityInput/Quantity";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

import "./CartSidebar.scss";
// import { Button } from "../../components/shared/Button/Button";

export const getCartItemPrices = (item: CartNode) => {
  const product = item.product.node;

  if (product.type === "SIMPLE") {
    return {
      regular: product.regularPrice,
      sale: product.salePrice,
    };
  }

  const variation = item.variation.node;

  return {
    regular: variation.regularPrice,
    sale: variation.salePrice,
  };
};

const getStockQuantity = (item: CartNode) => {
  const product = item.product.node;

  if (product.type === "SIMPLE") {
    return product.stockQuantity || undefined;
  }

  if (product.type === "VARIABLE") {
    return item?.variation?.stockQuantity || product.stockQuantity || undefined;
  }
};

export const CartSidebar = () => {
  const ref = useRef(null);
  const cartCtx = useCartContext();
  const { t } = useTranslation();
  const history = useHistory();
  const menuSidebar = useMenuSidebar();
  const [loading, setLoading] = useState("");
  const lngCtx = useLanguageContext();
  const prefix = lngCtx.getLangPrefix();

  const calculateTotal =
    cartCtx?.total &&
    parseFloat(cartCtx?.total) - parseFloat(cartCtx?.shippingTotal);

  const total = cartCtx?.shippingTotal
    ? calculateTotal?.toString()
    : cartCtx?.total;

  const handleQuantityUpdate = async (value: number, item: CartNode) => {
    try {
      setLoading(item?.product?.id);
      await cartCtx.updateProductQuantity(item.key, value);
    } catch (e) {
    } finally {
      setLoading("");
    }
  };

  // const emptyCart = () => {};

  return (
    <div
      ref={ref}
      className={cn("CartSidebar", menuSidebar.cart && "CartSidebar--opened")}
    >
      {(cartCtx.isUpdatingProduct || cartCtx.isEmptyLoading) && (
        <div className="CartSidebar--updating">
          <LoadingPlaceholder />
        </div>
      )}

      <HandleLoadingState
        loading={cartCtx.isLoading}
        loadingPlaceholder={
          <div className="CartSidebar-loader">
            <LoadingPlaceholder />
          </div>
        }
      >
        <div className="CartSidebar-head">
          <p onClick={() => history.push(`${prefix}/my-cart`)}>
            {t(fields.cart)}
          </p>
          <span role="button" onClick={() => menuSidebar.closeSidebar("cart")}>
            <Icon icon="x" />
          </span>
        </div>
        {/* {cartCtx.items && cartCtx.items?.length > 1 && (
          <div className="d-flex justify-content-end mt-2">
            <Button
              onClick={emptyCart}
              color="primary"
              type="submit"
              className="w-100-mobile flex-right"
            >
              Clear all
            </Button>
          </div>
        )} */}
        {cartCtx.isEmpty ? (
          <div className="CartSidebar-empty">{t(messages.emptyCart)}</div>
        ) : (
          <>
            <div className="CartSidebar-body">
              <ul className="CartSidebar-list">
                {cartCtx?.items?.map((item, i) => {
                  const product = item.product?.node;

                  const stockQuantity = getStockQuantity(item);

                  const prices = getProductPrices(item);

                  // const getAttributes = (value: string) => {
                  //   const res = item?.variation?.node?.attributes?.nodes?.filter(
                  //     (item) => {
                  //       return item.name === value;
                  //     }
                  //   );

                  //   return res;
                  // };

                  return (
                    <li
                      key={`${item?.product?.id}-${i}`}
                      className="CartSidebar-list-item"
                    >
                      <NavLink to={`${prefix}/product/${product?.slug}`}>
                        <div className="CartSidebar-list-item-image">
                          <Image
                            src={product?.image?.mediaItemUrl}
                            alt={item.product.name}
                          />
                        </div>
                      </NavLink>
                      <div className="CartSidebar-list-item-info">
                        <NavLink to={`${prefix}/product/${product?.slug}`}>
                          <h4>{product?.name}</h4>

                          <div className="d-flex align-items-center">
                            <h5>{t(fields.price)}:</h5>
                            <ProductPrices
                              sale={prices.sale}
                              regular={prices.regular}
                              onSale={product?.onSale}
                            />
                          </div>
                          {/* <div className="d-flex align-items-center">
                            <h5>{t(getAttributes("pa_madhesia")[0]?.name)}:</h5>
                            {getAttributes("pa_madhesia")[0]?.value}
                          </div>
                          <div className="d-flex align-items-center">
                            <h5>{t(getAttributes("pa_ngjyra")[0]?.name)}:</h5>
                            {getAttributes("pa_ngjyra")[0]?.value}
                          </div> */}
                        </NavLink>
                        <div className="mt-3">
                          <Quantity
                            disabled={cartCtx.isUpdatingProduct}
                            loading={loading === item?.product?.id}
                            disableDecrement={item?.quantity === 1}
                            disableIncrement={item?.quantity === stockQuantity}
                            maxQuantity={stockQuantity}
                            quantity={item.quantity}
                            onChange={(value: number) =>
                              handleQuantityUpdate(value, item)
                            }
                          />
                        </div>
                      </div>
                      <button
                        disabled={cartCtx.isUpdatingProduct}
                        onClick={() =>
                          cartCtx.updateProductQuantity(item.key, 0)
                        }
                        className="CartSidebar-list-item-remove"
                      >
                        <Icon icon="x" />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="CartSidebar-footer">
              <div className="CartSidebar-total">
                <span>{t(fields.subtotal)}:</span>
                <strong>{removeHTMLEntities(total.slice(0, 4))}€</strong>
                {/* <em>Duke përfshirë transportin</em> */}
              </div>
              <NavLink to={`${prefix}/checkout`}>
                {t(actions.continueWithPurchase)}{" "}
                <Icon
                  style={{
                    width: 16,
                    height: 16,
                    marginLeft: 10,
                    marginTop: 3,
                  }}
                  icon="arrow-right"
                />
              </NavLink>
            </div>
          </>
        )}
      </HandleLoadingState>
    </div>
  );
};
