import gql from "graphql-tag";

export type TranslationItemKey = "key" | "en" | "sq";

type TranslationItemType = {
  [key in TranslationItemKey]: string;
};

export interface TranslationItem extends TranslationItemType {}

export interface TranslationsResults {
  stringTranslations: {
    themeTranslations: {
      errorsStrings: TranslationItem[];
      fieldsStrings: TranslationItem[];
      actionsStrings: TranslationItem[];
      messagesStrings: TranslationItem[];
      taxonomiesStrings: TranslationItem[];
      attributesStrings: TranslationItem[];
      formInputsStrings: TranslationItem[];
      formMessagesStrings: TranslationItem[];
      formValidationStrings: TranslationItem[];
    };
  };
}

export const GET_TRANSLATIONS_QUERY = gql`
  query getTranslationsQuery {
    stringTranslations {
      themeTranslations {
        taxonomiesStrings {
          key
          en
          sq
        }
        actionsStrings {
          key
          en
          sq
        }
        errorsStrings {
          key
          en
          sq
        }
        fieldsStrings {
          key
          en
          sq
        }
        formInputsStrings {
          key
          en
          sq
        }
        formMessagesStrings {
          key
          en
          sq
        }
        formValidationStrings {
          key
          en
          sq
        }
        messagesStrings {
          key
          en
          sq
        }
        attributesStrings {
          key
          en
          sq
        }
      }
    }
  }
`;
