import React from "react";
import { useMutation } from "react-apollo";

import { formMessages } from "../../../lib/translation/strings";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNotification } from "../../../lib/context/NotificationContext/NotificationContext";
import { useProfilePasswordFormik } from "../../../lib/formik/profile/useProfilePasswordFormik";

import { UPDATE_PROFILE_PASSWORD_MUTATION } from "../../../queries/mutations/userMutations";

import { ProfilePasswordForm } from "./ProfilePasswordForm";

import "./ProfilePassword.scss";

export interface ProfilePasswordFields {
  profilePassword: string;
  confirmProfilePassword: string;
}

export const ProfilePassword = () => {
  const authCtx = useAuthContext();
  const notificationCtx = useNotification();
  const [updateProfilePassword, { loading }] = useMutation(
    UPDATE_PROFILE_PASSWORD_MUTATION
  );

  const formik = useProfilePasswordFormik({
    onSubmit: async (values) => {
      if (!formik.dirty) {
        return;
      }

      try {
        await updateProfilePassword({
          variables: {
            id: authCtx.user?.id,
            password: values.profilePassword
          }
        });

        notificationCtx.success(formMessages.profileUpdated);

        formik.resetForm();
      } catch (error) {
        console.error(error);
        notificationCtx.danger(formMessages.profileNotUpdated);
      }
    }
  });

  return (
    <div className="ProfilePassword">
      <ProfilePasswordForm loading={loading} formik={formik} />
    </div>
  );
};
