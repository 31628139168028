import React from "react";
import ContentLoader from "react-content-loader";

export const ShopLogoPlaceholder = () => {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={100}
      style={{ maxWidth: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" width="100%" height="100" />
    </ContentLoader>
  );
};
