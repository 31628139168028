import React from "react";
import ContentLoader from "react-content-loader";

export const ShopSidebarPlaceholder = () => (
  <ContentLoader
    speed={2}
    width={800}
    height={2000}
    viewBox="0 0 800 2000"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="15" rx="0" ry="0" width="262" height="3" />
    <rect x="0" y="0" rx="0" ry="0" width="111" height="12" />
    <rect x="0" y="100" rx="0" ry="0" width="111" height="12" />
    <rect x="0" y="32" rx="0" ry="0" width="262" height="30" />
    <rect x="0" y="115" rx="0" ry="0" width="262" height="3" />
    <rect x="0" y="132" rx="0" ry="0" width="262" height="14" />
    <rect x="1" y="157" rx="0" ry="0" width="90" height="24" />
    <rect x="0" y="215" rx="0" ry="0" width="111" height="12" />
    <rect x="0" y="230" rx="0" ry="0" width="262" height="3" />
    <rect x="0" y="244" rx="0" ry="0" width="58" height="38" />
    <rect x="68" y="244" rx="0" ry="0" width="58" height="38" />
    <rect x="136" y="244" rx="0" ry="0" width="58" height="38" />
    <rect x="204" y="244" rx="0" ry="0" width="58" height="38" />
    <rect x="0" y="292" rx="0" ry="0" width="58" height="38" />
    <rect x="68" y="292" rx="0" ry="0" width="58" height="38" />
    <rect x="136" y="292" rx="0" ry="0" width="58" height="38" />
    <rect x="204" y="292" rx="0" ry="0" width="58" height="38" />
    <rect x="0" y="340" rx="0" ry="0" width="58" height="38" />
    <rect x="68" y="340" rx="0" ry="0" width="58" height="38" />
    <rect x="136" y="340" rx="0" ry="0" width="58" height="38" />
    <rect x="204" y="340" rx="0" ry="0" width="58" height="38" />
    <rect x="0" y="388" rx="0" ry="0" width="58" height="38" />
    <rect x="68" y="388" rx="0" ry="0" width="58" height="38" />
    <rect x="136" y="388" rx="0" ry="0" width="58" height="38" />
    <rect x="204" y="388" rx="0" ry="0" width="58" height="38" />
    <rect x="0" y="436" rx="0" ry="0" width="58" height="38" />
    <rect x="68" y="436" rx="0" ry="0" width="58" height="38" />
    <rect x="136" y="436" rx="0" ry="0" width="58" height="38" />
    <rect x="204" y="436" rx="0" ry="0" width="58" height="38" />
  </ContentLoader>
);
