import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { Container as ReactContainer } from "reactstrap";

import { errors } from "../../lib/translation/strings";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import { Alert } from "../../components/Notifications/Alert";
import { RelatedProducts } from "../../components/RelatedProducts/RelatedProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ProductSingleContextProvider } from "../../lib/context/ProductSingleContext/ProductSingleContextProvider";
import { ProductSimpleAttributes } from "../../components/ui/ProductSimpleAttributes";
import { ProductSingleCarousel } from "../../components/ProductSingleCarousel/ProductSingleCarousel";
import { SingleProductContent } from "../../components/ProductSingleContent/ProductSingleContent";
import { SingleProductDetails } from "../../components/SingleProductDetails/SingleProductDetails";
import { SingleProductButtons } from "../../components/SingleProductButtons/SingleProductButtons";
import { AmazonLoader } from "./AmazonLoader";
import { Container } from "../../components/Container/Container";

const ProductSingleInner = () => {
  const { t } = useTranslation();
  const { loading, error, product } = useProductSingleContext();

  return (
    <>
      <div className={cs("SingleProduct")}>
        <Container>
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              <ReactContainer>
                <AmazonLoader />
              </ReactContainer>
            }
          >
            <ApolloErrorGuard error={error}>
              <ReactContainer>
                {!product ? (
                  <Alert type="warning" message={t(errors.noProductFound)} />
                ) : (
                  <div className="SingleProduct__wrapper">
                    <ProductSingleCarousel />
                    <div className="SingleProduct__content">
                      <SingleProductContent />
                      {/* {product.type === "VARIABLE" && (
                        <ProductSingleVariations />
                      )} */}
                      <SingleProductButtons />
                      <SingleProductDetails />
                    </div>
                  </div>
                )}
                <ProductSimpleAttributes />
              </ReactContainer>
              <RelatedProducts
                excludedID={product?.databaseId}
                category={product?.productCategories.nodes[0]?.slug || ""}
              />
            </ApolloErrorGuard>
          </HandleLoadingState>
        </Container>
      </div>
    </>
  );
};

export const ProductSingle = () => {
  return (
    <ProductSingleContextProvider>
      <ProductSingleInner />
    </ProductSingleContextProvider>
  );
};
