import React from "react";
import { Spinner } from "reactstrap";

import { useCartContext } from "../../lib/context/CartContext/CartContext";

import { CartItem } from "../../components/CartItem/CartItem";
import { CartNode } from "../../queries/cartQueries";

import "./CartList.scss";

interface Props {
  products?: CartNode[];
}

export const CartList = (props: Props) => {
  const cartCtx = useCartContext();

  return (
    <div className="CartList">
      {cartCtx.isUpdatingProduct ? (
        <div className="CartList__loader">
          <Spinner style={{ width: "5rem", height: "5rem" }} />
        </div>
      ) : (
        props.products?.map((item) => {
          return <CartItem item={item} key={`${item.key}-cart-listing`} />;
        })
      )}
    </div>
  );
};
