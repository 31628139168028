import React, { useContext } from "react";
import { AuthContextUserProps } from "./AuthContextProvider";
import { ResetPasswordProps } from "../../../queries/mutations/userMutations";
import { ExecutionResult } from "graphql";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: AuthContextUserProps;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
  checkAuthentication: () => void;
  // login: (username: string, password: string, redirect?: boolean) => void;
  login: (res: ExecutionResult, redirect?: boolean) => void;
  logout: (redirect?: string | false) => void;
  register: (res: ExecutionResult, noRedirect?: boolean) => void;
  updateUser: (values: AuthContextUserProps) => void;
  forgotPassword: (username: string) => Promise<any>;
  resetPassword: (input: ResetPasswordProps) => Promise<any>;
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  loginError: undefined,
  registerError: undefined,
  checkAuthentication: () => {},
  login: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {},
  forgotPassword: () => new Promise((resolve) => resolve()),
  resetPassword: () => new Promise((resolve) => resolve())
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
