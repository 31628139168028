import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNotification } from "../../../lib/context/NotificationContext/NotificationContext";
import { fields } from "../../../lib/translation/strings";

import { HeadingTitle } from "../../shared/HeadingTitle/HeadingTitle";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";

import "./CheckoutHeader.scss";

interface Props {
  formik: CheckoutFormik;
  error?: string;
}

export const CheckoutHeader = (props: Props) => {
  const { t } = useTranslation();
  const notificationCtx = useNotification();

  useEffect(() => {
    if (props.error) {
      notificationCtx.warning(props.error, false, "mb-4");
    }
  }, [props.error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <HeadingTitle title={t(fields.billingAndShipping)} />
    </>
  );
};
