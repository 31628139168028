import React from "react";
import { Icon } from "../shared/Icon/Icon";

import "./RemoveItemButton.scss";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

export const RemoveItemButton = (props: Props) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className="RemoveItemButton"
    >
      <Icon icon="trash-2" />
    </button>
  );
};
