import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import { useQuery } from "react-apollo";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { GET_CONTACT_OPTIONS } from "../../queries/generalQueries";
import { CONTACT_FORM } from "../../queries/mutations/contactMutation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import { ContactForm } from "./ContactForm";
import {
  errors,
  formValidation,
  messages,
} from "../../lib/translation/strings";

import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { BulkLoader } from "../../components/shared/BulkLoader/BulkLoader";
import { GoogleMaps } from "../../components/GoogleMaps/GoogleMaps";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";

import "./Contact.scss";

const ContactPlaceholder = () => {
  return (
    <div className="ContactPlaceholder">
      <ContentLoader
        height={500}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="0" width="200" height="30" />
        <rect x="0" y="60" rx="20" width="45%" height="40" />
        <rect x="55%" y="60" rx="20" width="45%" height="40" />
        <rect x="0" y="120" rx="20" width="100%" height="108" />
        <rect x="0" y="260" width="200" height="47" />

        <rect x="0" y="360" width="350" height="10" />
        <rect x="0" y="380" width="350" height="10" />
        <rect x="0" y="400" width="300" height="10" />
        <rect x="0" y="420" width="200" height="10" />
      </ContentLoader>
    </div>
  );
};

// const GoogleMapPlaceholder = () => {
//   return (
//     <div className="GoogleMapPlaceholder">
//       <ContentLoader
//         height={500}
//         backgroundColor="#f3f3f3"
//         foregroundColor="#ecebeb"
//         style={{ width: "100%" }}
//       >
//         <rect x="0" y="0" width="100%" height="100%" />
//       </ContentLoader>
//     </div>
//   );
// };

export const Contact = () => {
  const { t } = useTranslation();
  const notificationCtx = useNotification();

  const contactQuery = useQuery(GET_CONTACT_OPTIONS);
  const [contactFormSubmission, contactForm] = useMutation(CONTACT_FORM);

  const formik = useFormik({
    initialValues: {
      nameSurname: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      nameSurname: Yup.string().min(4).required(t(formValidation.firstName)),
      email: Yup.string().email().required(t(formValidation.email)),
      message: Yup.string().required(t(formValidation.message)),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await contactFormSubmission({
          variables: {
            nameSurname: values.nameSurname,
            email: values.email,
            message: values.message,
          },
        });

        notificationCtx.success(t(messages.contactSuccess));

        formikHelpers.resetForm();
      } catch (error) {
        notificationCtx.danger(errors.wentWrong);
      }
    },
  });

  const contact = contactQuery.data?.generalOptions?.generalOptions?.contact;

  return (
    <div className="Contact">
      <Container>
        <HandleLoadingState
          loading={contactQuery.loading}
          loadingPlaceholder={
            <BulkLoader length={1} component={ContactPlaceholder} />
          }
        >
          <ApolloErrorGuard error={contactQuery.error}>
            <>
              {/* <HeadingTitle title={t(fields.contact)} /> */}
              {contact?.content && (
                <Row>
                  <Col xs="12" md="6">
                    <div className="Contact__address">
                      <span>Na kontaktoni</span>
                      <h5>Zyra qendrore</h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: contact?.content,
                        }}
                      />
                      {/* <div className="Contact__address_infos">
                        <div className="Contact__address_infos--map">
                          <Icon icon="map" />
                          <span>Rr. Tahir Zajmi (tek Kosovatex)</span>
                        </div>
                        <div className="Contact__address_infos--email">
                          <Icon icon="mail" />
                          <span>info@s-sprint.net</span>
                        </div>
                        <div className="Contact__address_infos--phone">
                          <Icon icon="phone" />
                          <span>+383 (0) 49 279 649</span>
                        </div>
                        <div className="Contact__address_infos--clock">
                          <Icon icon="clock" />
                          <span>
                            Nga e hena deri te shtunen prej 9:00 deri 17:00
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <GoogleMaps
                      lat="42.644908"
                      lng="21.142463"
                      apiKey="AIzaSyBrRPR3vD0zsfOmyeDt_CNxIi-r1-rR8Jw"
                    />
                  </Col>
                </Row>
              )}
              <ThemeContainer>
                <ContactForm
                  formik={formik}
                  loading={contactQuery.loading || contactForm.loading}
                />
              </ThemeContainer>
            </>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
